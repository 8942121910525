import Vue from 'vue';
import isEqual from 'lodash/isEqual';
import StateInput from "../../vue/StateInput";

export default (el, props = {}) => new Vue({
    el,
    delimiters: ['${', '}'], // The only reason to use custom delimiters, is if you want to mix Vue and Twig templates,
    name: 'EditAddress',
    components: {
        StateInput
    },
    data() {
        return {
            address: {
                firstName: props.address.firstName,
                lastName: props.address.lastName,
                address1: props.address.address1,
                address2: props.address.address2,
                city: props.address.city,
                zipCode: props.address.zipCode,
                countryId: props.address.countryId || '',
                stateId: props.address.stateId || '',
                stateName: props.address.stateName
            },
            errors: props.errors,
            isPrimaryShippingAddress: props.isPrimaryShippingAddress,
            isPrimaryBillingAddress: props.isPrimaryBillingAddress
        };
    },
    computed: {
        countryId() {
            return this.address.countryId;
        },
        enableSubmit() {
            return !isEqual(this.address, props.address) || this.isPrimaryShippingAddress !== props.isPrimaryShippingAddress || this.isPrimaryBillingAddress !== props.isPrimaryBillingAddress;
        }
    },
    watch: {
        countryId() {
            // Wipe the state name and state ID when the country changes
            this.address = {
                ...this.address,
                stateName: '',
                stateId: ''
            };
        }
    },
    mounted() {
        // Focus on the first input with an error
        const { form } = this.$refs;
        const errorInput = form.querySelector('input[data-haserror],select[data-haserror]');
        if (!errorInput) {
            return;
        }
        try {
            errorInput.focus();
        } catch (error) {
            console.error(error);
        }
    }
});
