import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';
import debounce from 'lodash/debounce';
import gsap from 'gsap';

import { loadMatter, loadPaper } from "../lib/async-bundles";

import * as eventKeys from '../lib/events';

let Matter;
let Paper;

const FrontPageHeroMatter = el => {

    const shouldRender = false;

    const baseSize = 2300;
    const minSize = 375;
    const minScale = 0.23;

    const $el = $(el);
    const $canvas = $el.find('#frontpageHerocanvas');
    const $canvas2 = $el.find('#frontpageHerocanvas2');
    const canvas = $canvas.get(0);
    const canvas2 = $canvas2.get(0);

    let elemWidth = $el.width();
    let elemHeight = $el.height();

    let currentScale = 1;
    let portraitScaleAdjustment = 0.05;
    let isLandscape = elemWidth > elemHeight;
    let hasInteracted = false;
    let isPlaying = false;
    let menuOpen = false;
    let currentlyUsedWindowHeight;
    let currentlyUsedWindowWidth;

    let paper;
    let scriptTimeline;

    let boundaryTopText, boundaryMenu, boundaryTop, boundaryBottom, boundaryLeft, boundaryRight;
    const boundaryWidth = 200;

    let Engine = Matter.Engine,
        MatterEvents = Matter.Events,
        Render = Matter.Render,
        Runner = Matter.Runner,
        Common = Matter.Common,
        MouseConstraint = Matter.MouseConstraint,
        Mouse = Matter.Mouse,
        World = Matter.World,
        Composite = Matter.Composite,
        Vertices = Matter.Vertices,
        Svg = Matter.Svg,
        Bodies = Matter.Bodies;

    let engine, world, render, runner;
    let resizeHandler;

    let paths = [
        {
            id: 'T',
            startX: -520,
            startY: -272 - 50,
            startXPortrait: -290,
            startYPortrait: -380 - 50 - 100,
            startRotationPortrait: -5,
            currentRotation: 0,
            path: 'M366,107.3c-14.7-5.6-27.9-7.6-40.8-6.3c-25.3,2.6-48.6,17.3-77,41l30.3,266.2l-65.4,6.7l-25.3-222.7c-16.6,12.6-42,22.3-68.3,25C52.2,224.2,5.7,198.6,0.4,151.7c-4.9-42.7,35.3-81.8,105.1-89c40.6-4.1,81.9,6.4,110,24.8c31.9-27.4,65.2-43.6,97.3-46.9c15.8-1.6,31.5-0.4,46.1,4.3L366,107.3zM69.7,144.6c1.2,10.8,16.5,17.8,44.3,14.9c20.8-2.1,42.2-12.4,54.7-25.5c-15.9-12.1-40.8-14.8-58.1-13C82.9,123.9,68.5,133.9,69.7,144.6z',
            simplifiedTag: '<path id="simpl-t" d="M214 88L89 62 4 123l13 66 81 29 90-26 25 223 66-7-31-266 114-36V45l-67-1z"/>',
            pivotAdjust: {
                x: 0,
                y: -50
            },
            animateFrom: {
                x: 300,
                y: -400,
                rotation: -30,
                startDelayLandscape: 1,
                startDelayPortrait: 0.8,
                duration: 2.5
            }
        },
        {
            id: 'A1',
            startX: -250 + 10,
            startY: -263 + 10,
            startXPortrait: 47 + 10,
            startYPortrait: -396 + 10 - 100,
            startRotationPortrait: 0,
            currentRotation: 0,
            path: 'M642.2,242.7c4.1-21.4-7.1-40-41.7-44.5c-81.3-10,27.3-132.5-65.5-148.3c-30.5-5.2-55.6,15-67.1,40.8c-9.3,21.9-21.9,19.3-34.7,17.1c-18.7-3.2-36.5,7.7-39.7,24.4c-4.4,18,11.5,22.1,1,45.3c-12.5,28.1-42.5,10-49.6,47.2c-7,36.7,60.5,36.7,52.8,76.6c-7.9,41.3-81.5-3.4-90,41.2c-5.9,30.7,28.5,42.3,46.6,45.4c19.7,3.4,62.5,4.9,72.3-46.7c7.5-39.5,37.3-20,42.3-46c5.2-27.4-37.2-25.5-40.5-48.2l66.8,11.4c-2.1,16.4,5.3,28.2,17.5,30.3c17.2,2.9,26-16.7,42.7-13.8c12.8,2.2,21.4,15.6,18.7,29.6c-8.5,44.6-42.2,45.6-46.3,85.6c-1.5,16,12.3,31.3,34.4,35.1c30,5.1,54.4-13.7,59.3-39.8c6.2-32.5-25.7-54.2-21.5-76.1C603.8,289.8,636.3,273.8,642.2,242.7zM518.5,202.4l-55.5-9.5c-10.7-29.2,9.7-106.7,53.9-99.2C564.6,101.9,539.8,181.1,518.5,202.4z',
            simplifiedTag: '<path id="simpl-a1" d="M527 49l-127 70-53 99 51 83-72 22-19 35 59 31 51-17 110 17 41 37 50-30-18-81 43-87-70-38-2-116"/>',
            pivotAdjust: {
                x: 10,
                y: 10
            },
            animateFrom: {
                x: 300,
                y: -400,
                rotation: 50,
                startDelayLandscape: 0.9,
                startDelayPortrait: 0.7,
                duration: 2.2
            }
        },
        {
            id: 'L',
            startX: 85 - 51,
            startY: -219 + 45,
            startXPortrait: 400 - 51,
            startYPortrait: -393 + 45 - 100,
            startRotationPortrait: 20,
            currentRotation: 0,
            path: 'M691.5,469L662,115.9l65.9-4.9,24.6,294.3,197.6-14.7,4.9,58.8z',
            simplifiedTag: '<path id="simpl-l" d="M662 116l29 353 264-20-5-58-197 15-25-295"/>',
            pivotAdjust: {
                x: -51,
                y: 45
            },
            animateFrom: {
                x: 300,
                y: -400,
                rotation: 10,
                startDelayLandscape: 0.9,
                startDelayPortrait: 0.8,
                duration: 2.2
            }
        },
        {
            id: 'O',
            startX: 240 + 15,
            startY: -330,
            startXPortrait: -470 + 15,
            startYPortrait: -70 - 100,
            startRotationPortrait: 200,
            currentRotation: 0,
            path: 'M828.9,250.3c0-44.4-44.9-37.8-44.9-87.4c0-64.7,83.3-21.9,83.3-84.8C867.3,23.8,924.1,0,979.9,0C1086.2,0,1141,43.4,1141,110c0,49.1-23.4,64.2-23.4,91.7c0,26,12,28.8,12,62.4c0,68-93.7,94-157.1,94C861.8,358,828.9,323,828.9,250.3 M892.2,293.7c0,27.9,31.9,47.2,77.8,47.2c38.9,0,92.2-13.2,92.2-67.1c0-34.5-27.4-41.1-27.4-80.8c0-60,38.4-63.8,38.4-101.1c0-47.7-45.9-63.2-81.8-65.1c-35.4-1.4-59.8,17.2-59.8,43.2c0,17.5,16,24.8,16,43.7c0,52.4-91.2,14.2-91.2,67.6c0,32.1,47.9,27.4,47.9,62.8C904.2,265.4,892.2,267.8,892.2,293.7',
            simplifiedTag: '<path id="simpl-o" d="M990 0l-98 26-41 85-67 52 43 74 24 86 117 35 122-30 39-55-11-71 25-91-61-92"/>',
            pivotAdjust: {
                x: 15,
                y: 0
            },
            animateFrom: {
                x: 300,
                y: -400,
                rotation: -10,
                startDelayLandscape: 1.4,
                startDelayPortrait: 0.5,
                duration: 2.6
            }
        },
        {
            id: 'R',
            startX: 565 - 3,
            startY: -255 - 10,
            startXPortrait: -20 - 3,
            startYPortrait: -10 - 10 - 100,
            startRotationPortrait: -50,
            currentRotation: 0,
            path: 'M1271.4,62.6c0.7,24.1,36-0.8,82.1,6.8c48.1,7.9,96.4,36.5,84.8,100.2c-19.8,108.5-110.6,38.9-120.5,92.9c-2.6,14.4,2.2,32,35.8,33.7c17,0.9,62.9,9.4,53.8,59.7c-6.5,35.8-41.8,90.5-91.3,82.3c-20.6-3.4-35.2-17.3-33.9-30c3.5-38.7,50.1-31.1,56.2-64.6c3.1-16.8-10.7-32.9-29.3-36c-26.5-4.4-42,22.8-68.5,18.5c-5.9-1-11-3.2-15.9-6.9l-16.9,92.6l-64.7-10.6L1206.7,52L1271.4,62.6z M1287.6,206.3c4.7-25.6,25.9-25.5,42.4-19.4c21.7,8.4,42.6,4.6,48.3-29.5c4.2-25.7-15.7-52.4-49.5-58c-29.4-4.8-66.1,10.7-76.4,67l-9.3,51.2c-4.7,25.6,7.3,40.5,20.5,42.7C1294,265.3,1284.6,223,1287.6,206.3z',
            simplifiedTag: '<path id="simpl-r" d="M1207 52l-64 349 64 14 18-96 77 114 61-5 44-68-19.9-56.2L1321 290l6-44 81-18 32-58-6-53-77-47"/>',
            pivotAdjust: {
                x: -3,
                y: -10
            },
            animateFrom: {
                x: 300,
                y: -400,
                rotation: 170,
                startDelayLandscape: 1.0,
                startDelayPortrait: 0.4,
                duration: 2.4
            }
        },
        {
            id: 'M',
            startX: -500 + 6,
            startY: 100 - 8,
            startXPortrait: 425 + 6,
            startYPortrait: 21 - 8 - 100,
            startRotationPortrait: 0,
            currentRotation: 0,
            path: 'M200.4,635.3c-1.5,12.7,5.2,29,22,30.5c32.2,3,35.7-44.1,37.4-58.7c5.6-48.4-44-61.5-38.8-106.6c2.4-21.1,23.1-34.9,39-33.4c26.3,2.5,24,21.7,51.3,24.2c13.9,1.3,30.1-4.3,33.1-34.9l2-21.6l63.5,5.9L369.1,793l-63.5-5.9l27.6-233.8c1.3-10.8-4.7-24.6-19-26c-12.9-1.2-19.1,8.7-20.1,18.1c-2.3,20.2,21.1,25.7,17.9,52.9c-2.4,20.7-16.4,29.3-26.9,33.1c-19.4,7.2-20.5,29.4-21,38.4c-1.1,18.4-1.4,29.3-1.1,44c0.8,40.9-25,47-51.8,44.5c-19.8-1.9-44.8-16-41.5-44.2c3.8-32.9,26.3-57.8,0.5-60.7c-23.8-2.7-43-14-40.4-36.5c2.6-22.5,15.3-24.2,19.9-63.2c1.7-14.6-10.7-24.7-21.6-25.7c-17.9-1.7-21,12.3-23,30.1L81,766.1l-64-6L57.9,408l64,6c-3.6,31,1.8,40.5,14.2,41.7c20.3,1.9,25.3-15.2,45.2-13.3c8.9,0.8,29.5,8.9,27.4,27.7c-4.2,36.6-24.4,33.3-27.5,59.6c-1.7,14.6,1.4,27.2,17.1,29.6c12.8,2.1,29.5,9.4,27.5,26.8C223.6,604.3,203.4,609.5,200.4,635.3',
            simplifiedTag: '<path id="simpl-m" d="M58 408L17 760l64 6 15-130 86 44-12 47 68 30 23-25 50 7-4 48 68 6 35-354-66-6-10 51-151-38-61-31"/>',
            pivotAdjust: {
                x: 6,
                y: -8
            },
            animateFrom: {
                x: 300,
                y: -400,
                rotation: -40,
                startDelayLandscape: 0.4,
                startDelayPortrait: 0.6,
                duration: 3
            }
        },
        {
            id: 'A2',
            startX: -130 - 10,
            startY: 140 + 6,
            startXPortrait: -346 - 10,
            startYPortrait: 302 + 6 - 100,
            startRotationPortrait: 30,
            currentRotation: 0,
            path: 'M752.7,593.8c4.7,31.4-51.2,68.8-48.2,88.5c3.3,22,40.7,33.2,45.6,66c3.9,26.2-12.9,51.4-43,55.5c-22.2,3-40.4-7.4-44.2-23.2c-9.4-39.3,22.1-50.3,15.4-95.2c-2.1-14-14.7-24.3-27.5-22.6c-16.8,2.3-18.6,23.5-35.9,25.9c-12.3,1.7-23.2-7.4-26.7-23.6l-67.1,9.1c10.6,20.5,50.2,6.1,54.4,33.7c3.9,26.2-30.7,16.6-24.8,56.4c7.8,52-33.3,63.3-53,65.9c-18.2,2.5-54.6,1.7-59.2-29.3c-6.7-44.9,77.8-24.4,71.6-66.1c-6-40.3-69.9-20.2-75.5-57.1c-5.6-37.5,28.8-29.2,31.3-59.7c2.3-25.1-14.2-24.4-16-42.7c-2.5-16.9,10.7-32.5,29.5-35c12.8-1.7,25.7-3,27.2-26.6c2.3-28,19.5-54.7,50-58.8c93.2-12.6,55.9,134.9,141.8,109.1C731.7,553.8,749.5,572.2,752.7,593.8M536,612.3l67.5-7.5c13.1-26.6-1.5-109.4-49.3-102.9C509.8,507.9,516.2,587.8,536,612.3',
            simplifiedTag: '<path id="simpl-a2" d="M551 786l-32 39h-63l-18-32 69.5-41.5-39-47L434 671l16-106 60-68 47-43 48 15 57 93h71l20 32-24 49-25 37 49 72-46 51-45-56z"/>',
            pivotAdjust: {
                x: -10,
                y: 6
            },
            animateFrom: {
                x: 300,
                y: -400,
                rotation: -120,
                startDelayLandscape: 0.6,
                startDelayPortrait: 0.3,
                duration: 2.3
            }
        },
        {
            id: 'D',
            startX: 210 - 20,
            startY: 190 + 6,
            startXPortrait: 0 - 20,
            startYPortrait: 414 + 6 - 100,
            startRotationPortrait: 10,
            currentRotation: 0,
            path: 'M874.2,503.7c-1.2,10.8,6.5,18.2,20.3,19.6c30.2,3,55.4-17.2,84.6-14.3c35.1,3.5,57.4,28.1,54.3,55.8c-3,27.3-24.9,39.3-27.1,59.5c-1.2,10.8,5.1,21.9,31,26.9c45,8.8,63.2,29.6,59,67.7c-5.1,45.6-73.7,46.8-94.1,72.8c-7.6,9.7-6.3,25-7.3,34.4c-2.5,13.1-17.8,21-31.7,19.6c-27.2-2.7-22.5-17.9-33.4-19c-24.2-2.4-6.8,38.3-38.4,35.1l-121.6-12.2l39.2-352.5L874.2,503.7z M995.1,764.9c16.3,1.6,37.3-11.9,40.6-41.1c1.8-16-5.6-44.3-49.4-50.6c-34.4-5.4-36.6-21.7-34.4-41c3.8-33.8,1.9-66.8-40.6-71.1c-23.2-2.3-44.8,11.7-47.7,37.5L846.9,749c-2,18.3-6.2,55.5,24.5,58.5c52.4,5.3,22.7-82.8,66.7-78.4C976.7,733.1,957.1,761,995.1,764.9z',
            simplifiedTag: '<path id="simpl-d" d="M808.5 497.5l-39 352 132 13 23-38 39 21 30-14 33-56 66-43v-53l-84-44 2-24 23-56-42-44-51 4-46 8-25-20z"/>',
            pivotAdjust: {
                x: -20,
                y: 6
            },
            animateFrom: {
                x: 300,
                y: -400,
                rotation: -100,
                startDelayLandscape: 0.4,
                startDelayPortrait: 0.2,
                duration: 2.4
            }
        },
        {
            id: 'E',
            startX: 520 - 18,
            startY: 120 + 14,
            startXPortrait: 370 - 18,
            startYPortrait: 365 + 14 - 100,
            startRotationPortrait: 90,
            currentRotation: 0,
            path: 'M1203.9,704.9c21.8,36,65.2,48.2,104.8,26.5c30.6-16.7,43.2-43.2,42.6-70.7l63.6,3.9c0.5,52-35.1,95.4-75.5,117.6c-73.2,40-151.3,20.7-190.7-44.4c-13.1-21.7-19.1-46.7-18.1-69.6c-14.5-11.7-27.2-26-37.3-42.8c-36.6-60.6-13.4-127.2,49.9-161.8c30.6-16.7,66.9-21.9,98.8-13.7l-18.3,56.9c-15.8-3.4-34.3-0.8-49.8,7.6c-30.6,16.7-44.6,49.4-25,81.8l3.6,5.1c12.8-19.5,30.3-36.2,52.6-48.4c41.8-22.8,87.6-15.2,111.6,24.5c24,39.7,7.1,82.7-34.7,105.6c-27.1,14.8-54.9,19.1-81.6,15.7L1203.9,704.9z M1264.4,605.9c-6.7-11.1-20.7-11.5-32.3-5.2c-13.8,7.5-26.7,21.2-33.7,38c19.5,6.7,39,4.8,54.5-3.7C1264.4,628.8,1271.1,617,1264.4,605.9z',
            simplifiedTag: '<path id="simpl-e" d="M1239.5 450.5l-16 56 17 34 71 29 12 70 28 21 63 4-44 95-105 44-101-44-89-172 26-90 68-44z"/>',
            pivotAdjust: {
                x: -18,
                y: 14
            },
            animateFrom: {
                x: 300,
                y: -400,
                rotation: -10,
                startDelayLandscape: 0.8,
                startDelayPortrait: 0.3,
                duration: 2.6
            }
        },
    ];

    const onMenuBeforeOpen = e => {
        menuOpen = true;
        stopEngine();
    };

    const onMenuAfterClose = e => {
        menuOpen = false;
        checkInViewport();
    };


    const init = () => {
        currentScale = getCurrentScale();
        createScene();

        currentlyUsedWindowHeight = Viewport.height;
        currentlyUsedWindowWidth = Viewport.width;
        updateContainerHeight();

        resizeHandler = debounce(onResize, 200);
        window.addEventListener('resize', resizeHandler);
        Dispatch.on(eventKeys.MENU_BEFORE_OPEN, onMenuBeforeOpen);
        Dispatch.on(eventKeys.MENU_AFTER_CLOSE, onMenuAfterClose);
        Viewport.on('scroll', onScroll);
    };

    const updateContainerHeight = () => {
        $el.css('height', '');
        $el.css('height', $el.height());
    };

    const getCurrentScale = () => {
        const calcWidth = Math.max(Math.min(baseSize, elemWidth), minSize);
        const widthPercent = (calcWidth - minSize) / (baseSize - minSize);

        return (((1 - minScale) * widthPercent) + minScale) + (!isLandscape ? portraitScaleAdjustment : 0);
    };

    const createScene = (onResize = false) => {
        engine = Engine.create();
        world = engine.world;

        paper = new Paper.PaperScope();
        paper.setup(canvas2);
        paper.view.autoUpdate = false;

        world.gravity.y = 0;
        //world.frictionAir = 0.6;

        setTimeout(() => {
            //world.gravity.y = 0.6;
        }, 3000);

        // create renderer
        render = Render.create({
            element: el,
            canvas: canvas,
            engine: engine,
            options: {
                wireframes: true,
                background: '#FFF3F6',
                width: $el.width(),
                height: $el.height(),
                showMouse: false,
                showBounds: true,
            }
        });

        // Create runner
        runner = Runner.create();

        // debug
        //paths = paths.slice(4,5);

        // Build paths
        paths.forEach((item, i) => {
            const $svg = $('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1139.5 551">' + item.simplifiedTag + '</svg>');
            let points = Svg.pathToVertices($svg.find('path').get(0), 10);

            let vertices = Vertices.scale(points, currentScale, currentScale);
            let color = Common.choose(['#556270', '#4ECDC4', '#337711', '#762211']);
            let startX = isLandscape ? item.startX : item.startXPortrait;
            let startY = isLandscape ? item.startY : item.startYPortrait;

            let body = Bodies.fromVertices(elemWidth / 2 + (startX * currentScale), elemHeight / 2 + (startY * currentScale), vertices, {
                label: 'path' + item.id,
                friction: 0.2,
                frictionAir: 0.03,
                restitution: 0,
                //angle: isLandscape ? 0 : item.startRotationPortrait * (Math.PI / 180),
                render: {
                    wireframes: false,
                    fillStyle: 'transparent',
                    strokeStyle: color,
                    lineWidth: 1,
                    label: 'shape_' + i
                }
            }, false);

            if (!isLandscape) {
                Matter.Body.setAngle(body, item.startRotationPortrait * (Math.PI / 180));
            }

            item.body = body;
            World.add(world, body);

            let paperPath = new paper.CompoundPath(item.path);
            paperPath.pivot = new paper.Point(paperPath.position.x + (item.pivotAdjust.x), paperPath.position.y + (item.pivotAdjust.y));

            paperPath.fillColor = '#D61D64';
            paperPath.strokeColor = '#FFF3F6';

            /*
            paperPath.fillColor = 'transparent';
            paperPath.strokeColor = '#D61D64';
            */

            paperPath.strokeWidth = 0;
            //paperPath.fullySelected = true;

            paperPath.scale(currentScale, currentScale);
            item.paperPath = paperPath;
        });

        // fit the render viewport to the scene
        Render.lookAt(render, {
            min: { x: 0, y: 0 },
            max: { x: elemWidth, y: elemHeight }
        });

        enableMouseControl();
        createAreaBoundaries();
        drawPaper();
        createScript();

        MatterEvents.on(runner, 'afterTick', event => {
            handleAfterTick(event);
        });

        if (!onResize) {
            if (window.frontpageAnimationCallback) {
                window.frontpageAnimationCallback();
            }

            animateIn();
        } else {
            runEngine(onResize);
        }
    };

    const runEngine = (onResize) => {
        Runner.run(runner, engine);

        if (shouldRender) {
            Render.run(render);
        }

        if (!onResize && !hasInteracted) {
            startScript();
        }

        isPlaying = true;
    };

    const stopEngine = () => {
        Runner.stop(runner, engine);
        isPlaying = false;
    };

    const handleAfterTick = event => {
        drawPaper();
    };

    const enableMouseControl = () => {
        let mouse = Mouse.create(render.canvas);
        let mouseConstraint = MouseConstraint.create(engine, {
            mouse: mouse,
            constraint: {
                stiffness: 0.3,
                render: {
                    visible: false
                }
            }
        });

        mouse.element.removeEventListener('mousewheel', mouse.mousewheel);
        mouse.element.removeEventListener('DOMMouseScroll', mouse.mousewheel);

        mouse.element.removeEventListener('touchmove', mouse.mousemove);
        mouse.element.removeEventListener('touchstart', mouse.mousedown);
        mouse.element.removeEventListener('touchend', mouse.mouseup);

        mouse.element.addEventListener('touchstart', onTouchStartCheck);
        mouse.element.addEventListener('touchend', onTouchEndCheck);
        
        World.add(world, mouseConstraint);

        // keep the mouse in sync with rendering
        render.mouse = mouse;

        $el.on('mousemove', () => {
            if (world && render && world.bodies && render.mouse) {
                if (Matter.Query.point(world.bodies, render.mouse.position).length > 0) {
                    $el.get(0).style.cursor = 'grab';
                } else {
                    $el.get(0).style.cursor = '';
                }
            }
        });

        MatterEvents.on(mouseConstraint, 'startdrag', () => {
            hasInteracted = true;
            stopScript();
        });
    };
    
    const onTouchStartCheck = e => {
        let position = render.mouse.position;
        
        if (e.touches && e.touches.length > 0) {
            position = { x: e.touches[0].pageX, y: e.touches[0].pageY }
        }
        
        if (Matter.Query.point(world.bodies, position).length > 0) {
            render.mouse.mousedown(e);
            render.mouse.element.addEventListener('touchmove', render.mouse.mousemove);
        } 
    };

    const onTouchEndCheck = e => {
        render.mouse.mouseup(e);
        render.mouse.element.removeEventListener('touchmove', render.mouse.mousemove);
    };

    const drawPaper = () => {
        paths.forEach((item, i) => {
            let path = item.paperPath;
            let currentRotation = item.currentRotation;
            path.position.x = item.body.position.x;
            path.position.y = item.body.position.y;

            let newRotation = (item.body.angle * (180 / Math.PI));
            path.rotate(newRotation - currentRotation);

            item.currentRotation = newRotation;

            if (paper && paper.view) {
                paper.view.update();
            }
        });
    };

    const animateIn = () => {
        const initialDelay = 0.2;

        let timeline = gsap.timeline({
            repeat: 0,
            onUpdate: () => {
                if (paper && paper.view) {
                    paper.view.update();
                }
            },
            onComplete: () => {
                runEngine();
            }
        });

        paths.forEach((item, i) => {
            let path = item.paperPath;
            let animateFrom = item.animateFrom;

            animateFrom.y = -400;
            animateFrom.x = path.position.x;

            if (animateFrom) {
                path.rotate(animateFrom.rotation - item.currentRotation);
                animateFrom.lastRotation = animateFrom.rotation;

                timeline.fromTo(animateFrom,
                    {
                        x: animateFrom.x,
                        y: animateFrom.y,
                    },
                    {
                        duration: animateFrom.duration,
                        x: path.position.x,
                        y: path.position.y,
                        ease: 'elastic.out(0.4, 0.5)',
                        onUpdateParams: [animateFrom, item],
                        onUpdate: function (animateDef, animateItem) {
                            animateItem.paperPath.position.x = animateDef.x;
                            animateItem.paperPath.position.y = animateDef.y;
                        }
                    }, initialDelay + (isLandscape ? animateFrom.startDelayLandscape : animateFrom.startDelayPortrait));

                timeline.to(animateFrom, {
                    duration: animateFrom.duration + 0.4,
                    rotation: item.currentRotation,
                    ease: 'back.out',
                    onUpdateParams: [animateFrom, item],
                    onUpdate: function (animateDef, animateItem) {
                        animateItem.paperPath.rotate(animateDef.rotation - animateDef.lastRotation);
                        animateDef.lastRotation = animateDef.rotation;
                        //console.log(animateDef.lastRotation, animateDef.rotation);
                    }
                }, initialDelay + (isLandscape ? animateFrom.startDelayLandscape : animateFrom.startDelayPortrait));

            }
        });
    };

    const createAreaBoundaries = () => {
        const $headerInnerLeft = $('.page-header [data-inner-left-element]');
        const $cartButton = $('.page-header [data-cart-button]');

        const menuBoundaryWidth = elemWidth - $cartButton.offset().left;
        const topTextBoundaryWidth = $headerInnerLeft.offset().left + $headerInnerLeft.width();
        const boundaryHeight = $cartButton.offset().top + $cartButton.height();

        //boundaryTopText = Bodies.rectangle(topTextBoundaryWidth / 2, boundaryHeight / 2, topTextBoundaryWidth, boundaryHeight, { isStatic: true, visible: true, fillColor: '#f00', label: 'boundary' });
        //boundaryMenu = Bodies.rectangle(elemWidth - (menuBoundaryWidth / 2), boundaryHeight / 2, menuBoundaryWidth, boundaryHeight, { isStatic: true, visible: true, fillColor: '#f00', label: 'boundary' });

        boundaryTop = Bodies.rectangle(elemWidth / 2, -boundaryWidth / 2, elemWidth, boundaryWidth, { isStatic: true, visible: false, label: 'boundary' });
        boundaryBottom = Bodies.rectangle(elemWidth / 2, elemHeight + (boundaryWidth / 2), elemWidth, boundaryWidth, { isStatic: true, visible: false, label: 'boundary' });
        boundaryLeft = Bodies.rectangle(-boundaryWidth / 2, elemHeight / 2, boundaryWidth, elemHeight, { isStatic: true, visible: false, label: 'boundary' });
        boundaryRight = Bodies.rectangle(elemWidth + (boundaryWidth / 2), elemHeight / 2, boundaryWidth, elemHeight, { isStatic: true, visible: false, label: 'boundary' });

        World.add(world, [boundaryTop, boundaryBottom, boundaryLeft, boundaryRight]);
    };

    const applyForce = (itemIndex, xForce, yForce, rotationForce) => {
        const item = paths[itemIndex];
        const body = item.body;

        Matter.Body.setVelocity(body, { x: xForce, y: yForce });
        Matter.Body.setAngularVelocity(body, rotationForce);
    };

    const createScript = () => {
        scriptTimeline = gsap.timeline({
            repeat: 0
        });

        scriptTimeline.call(() => {
            applyForce(0, 16, 10, 0);
        }, [], 3.5);

        scriptTimeline.call(() => {
            applyForce(8, -18, 0, 0);
        }, [], 5.5);

        scriptTimeline.call(() => {
            applyForce(8, 0, -10, 0);
        }, [], 6.1);

        scriptTimeline.call(() => {
            applyForce(8, 6, 6, 0.07);
        }, [], 7.9);

        scriptTimeline.pause();
    };

    const startScript = () => {
        if (scriptTimeline && !hasInteracted) {
            scriptTimeline.play();
        }
    };

    const stopScript = () => {
        if (scriptTimeline) {
            scriptTimeline.pause();
        }
    };

    const onResize = e => {
        if (Math.abs(currentlyUsedWindowHeight - Viewport.height) > 150 || Viewport.width !== currentlyUsedWindowWidth) {
            currentlyUsedWindowHeight = Viewport.height;
            currentlyUsedWindowWidth = Viewport.width;
            updateContainerHeight();

            elemWidth = $el.width();
            elemHeight = $el.height();
            isLandscape = elemWidth > elemHeight;
            currentScale = getCurrentScale();

            destroyEngine();

            canvas.width = Math.ceil(elemWidth);
            canvas.height = Math.ceil(elemHeight);
            canvas2.width = Math.ceil(elemWidth);
            canvas2.height = Math.ceil(elemHeight);

            paths.forEach((item, i) => {
                item.currentRotation = 0;
            });

            createScene(true);
        }
    };

    const onScroll = e => {
        checkInViewport();
    };

    const checkInViewport = () => {
        if (!menuOpen) {
            if (!isPlaying && Viewport.visible($el.get(0))) {
                runEngine();
                startScript();
            } else if (isPlaying && !Viewport.visible($el.get(0))) {
                stopEngine();
                stopScript();
            }
        }
    };

    const destroyEngine = () => {
        Matter.Engine.clear(engine);

        if (paper) {
            paper.view.onFrame = null;
            paper.view.onResize = null;
            paper.remove();
            paper = null;
        }
    };

    const destroy = () => {
        stopEngine();
        destroyEngine();
        window.removeEventListener('resize', resizeHandler);
        Viewport.off('scroll', onScroll);
        Dispatch.off(eventKeys.MENU_BEFORE_OPEN, onMenuBeforeOpen);
        Dispatch.off(eventKeys.MENU_AFTER_CLOSE, onMenuAfterClose);
    };

    return {
        init,
        destroy
    };

};

/**
 * Small wrapper around the FrontPageHeroMatter component, async-loading Matter and Paper and then booting
 *
 * @param el
 * @returns {{destroy(): (undefined)}}
 */
export default el => {

    let frontPageHeroMatter;

    Promise
        .all([
            new Promise(resolve => loadMatter(matterjs => {
                Matter = matterjs.default;
                resolve();
            })),
            new Promise(resolve => loadPaper(paperjs => {
                Paper = paperjs;
                resolve();
            }))
        ])
        .then(() => {
            frontPageHeroMatter = new FrontPageHeroMatter(el);
            frontPageHeroMatter.init();
        })
        .catch(error => {
            console.error(error);
        });

    return {
        destroy() {
            if (!frontPageHeroMatter) return;
            frontPageHeroMatter.destroy();
            frontPageHeroMatter = null;
        }
    };
};
