<script>
    export default {
        name: "StateSelect",
        delimiters: ['${', '}'], // The only reason to use custom delimiters, is if you want to mix Vue and Twig templates
        props: {
            countryId: Number|String,
            excludeCountryIds: Array,
            stateId: Number,
            states: Array
        },
        render() {
            return this.$scopedSlots.default({
                showStateField: this.showField,
                showStateDropdown: this.showDropdown,
                stateOptions: this.stateOptions
            });
        },
        computed: {
            statesByCountryId() {
                if (!this.states) {
                    return [];
                }
                return this.states.reduce((carry, state) => {
                    carry[`${state.countryId}`] = (carry[`${state.countryId}`] || []).concat([state]);
                    return carry;
                }, {});
            },
            stateOptions() {
                const countryId = this.countryId ? this.countryId.toString() : null;
                if (!countryId) {
                    return [];
                }
                const statesByCountryId = this.statesByCountryId;
                return statesByCountryId[countryId] || [];
            },
            showDropdown() {
                return this.stateOptions instanceof Array && !!this.stateOptions.length;
            },
            showField() {
                return !!this.countryId && (this.excludeCountryIds || []).indexOf(this.countryId.toString()) === -1;
            }
        },
        mounted() {
            console.log(this.excludeCountryIds);
        }
    }
</script>

<style scoped>

</style>
