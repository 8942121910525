import Vue from 'vue';
import gsap from 'gsap';
import { getAxios } from "../../lib/helpers";

export default (el, props) => new Vue({
    el,
    delimiters: ['${', '}'], // The only reason to use custom delimiters, is if you want to mix Vue and Twig templates,
    name: 'MembershipCard',
    data() {
        return {
            detailsExpanded: false,
            upcomingExpanded: false,
            upcomingShipmentStatuses: props.upcomingShipmentStatuses || {}
        };
    },
    methods: {
        /**
         * Toggle an upcoming ship on or off
         * @param e
         */
        onBatchStatusChange(e) {
            const { target } = e;
            const { value: batchId, checked: isActive } = target;
            const { membershipId } = props;

            const client = getAxios();
            const wasActive = !isActive;

            const upcomingShipmentStatus = this.upcomingShipmentStatuses[`batch:${batchId}`] || {};

            let isExtra = !!upcomingShipmentStatus.isExtra;
            let isCancelled = !!upcomingShipmentStatus.isCancelled;

            if (!isActive) {
                isCancelled = !isExtra;
                isExtra = false;
            } else {
                isExtra = !isCancelled;
                isCancelled = false;
            }

            this.upcomingShipmentStatuses = {
                ...this.upcomingShipmentStatuses,
                [`batch:${batchId}`]: {
                    isExtra,
                    isCancelled
                }
            };

            client
                .post(null, {
                    action: 'talormade/memberships/default/toggle-shipment-status',
                    batchId: parseInt(batchId, 10),
                    membershipId: parseInt(membershipId, 10)
                })
                .then(({ status, data }) => {
                    const { isRegular, isExtra, isCancelled } = data || {};
                    if (status !== 200 || [isRegular, isExtra, isCancelled].filter(status => typeof status === 'boolean').length < 3) {
                        throw new Error();
                    }
                })
                .catch(error => {
                    console.error(error);
                    // Reset if something went wrong
                    target.checked = wasActive;
                    this.upcomingShipmentStatuses = {
                        ...this.upcomingShipmentStatuses,
                        [`batch:${batchId}`]: upcomingShipmentStatus
                    };
                });
        },
        /**
         * --------- Transitions --------
         */
        onBeforeDetailsEnter(node) {
            gsap.set(node, { height: 0, opacity: 0 });
        },

        onDetailsEnter(node, onComplete) {
            gsap
                .timeline({ onComplete })
                .to(node, 0.75, { height: 'auto', ease: 'Quint.easeOut' }, 0)
                .to(node, 0.5, { opacity: 1, ease: 'Cubic.easeIn' }, 0);
        },

        onDetailsLeave(node, onComplete) {
            gsap
                .timeline({ onComplete })
                .to(node, 0.5, { height: 0, ease: 'Cubic.easeInOut' }, 0)
                .to(node, 0.35, { opacity: 0, ease: 'Cubic.easeIn' }, 0);
        }
    }
});
