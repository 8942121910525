import './lib/polyfills';
import lazySizes from './lib/lazysizes';
import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Components from '@vaersaagod/tools/Components';
import Dispatch from "@vaersaagod/tools/Dispatch";
import { nl2br } from "./lib/helpers";
import { USER_LOGGED_IN } from "./lib/events";

import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

// Add lodash' get to Vue
import Vue from 'vue';
import get from 'lodash/get';

// Add some global custom components
import Dropdown from "./vue/Dropdown";

Vue.component('dropdown', Dropdown);

Object.defineProperty(Vue.prototype, '$_get', {
    get: function () {
        return get;
    }
});

Vue.filter('nl2br', function (value) {
    if (!value) {
        return null;
    }
    return nl2br(value);
});

Vue.filter('formatCurrency', function (amount, decimalCount = 2, decimal = '.', thousands = ',') {

    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
});

/*
*   Bootstrap app
*
 */
const init = () => {

    Viewport.init();
    Components.init();
    lazySizes.init();

    // On AJAX login, refresh CSRF inputs
    Dispatch.on(USER_LOGGED_IN, (key, data) => {
        const { csrfToken } = data;
        if (!csrfToken || !window.csrfTokenName) return;
        window.csrfTokenValue = csrfToken;
        $(`input[type="hidden"][name="${window.csrfTokenName}"]`).val(csrfToken);
    });
    
    $('body').on('click', 'a[data-to-top-link]', e => {
        e.preventDefault();
        gsap.to(window, { duration: 1.1, scrollTo: 0, ease: 'Quint.easeInOut' });
    });

    window.addEventListener('popstate', e => {
        const { url } = e.state || {};
        if (url) {
            window.location.href = `/${url}`;
        } else {
            window.location.reload();
        }
    });
};

require('doc-ready')(init);
