import $ from '@vaersaagod/tools/Dom';

import { triggerNativeEvent } from "../../lib/helpers";

export default (el, props) => {

    const $el = $(el);
    const { uncheckable = true } = props || {};

    if (!uncheckable) return;

    $el.on('click', 'label span', e => {
        const label = $(e.target).parent('label').get(0);
        const input = $el.find(`input[id="${label.getAttribute('for')}"]`).get(0);
        if (input.checked) {
            e.preventDefault();
            input.checked = false;
            triggerNativeEvent('change', input);
        }
    });

    return {
        destroy() {
            $el.off('click');
        }
    };
};
