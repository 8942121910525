import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';

import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);

    const $letterG = $el.find('#gf-letter-g');
    const $letterO2 = $el.find('#gf-letter-o-2'); // ytre
    const $letterO1 = $el.find('#gf-letter-o-1');
    const $letterD = $el.find('#gf-letter-d');
    const $letterF = $el.find('#gf-letter-f');
    const $letterE2 = $el.find('#gf-letter-e-2');
    const $letterE1 = $el.find('#gf-letter-e-1'); // ytre
    const $letterL = $el.find('#gf-letter-l');
    const $letterS = $el.find('#gf-letter-s');

    let timeline = null;
    let rotateTimeline = null;
    let isPlaying = false;
    let skipViewportCheck = props ? (props.skipViewportCheck || false) : false;

    const onResize = e => {

    };

    const onScroll = e => {
        checkInViewport();
    };

    const checkInViewport = () => {
        if (!isPlaying && Viewport.visible($el.get(0))) {
            rotateTimeline.play();
            timeline.play();
            isPlaying = true;
        } else if (isPlaying && !Viewport.visible($el.get(0))) {
            rotateTimeline.pause();
            timeline.pause();
            isPlaying = false;
        }
    };

    const init = () => {
        const letterDuration = 0.2;
        const startPoint = 0.2;
        const returnPoint = 0.4;

        timeline = gsap.timeline({ repeat: -1 });
        timeline.to($letterG.get(0), { x: -18, y: -35, duration: letterDuration, ease: 'Quad.easeOut' }, startPoint);
        timeline.to($letterG.get(0), { x: 0, y: 0, duration: letterDuration, ease: 'Quad.easeInOut' }, returnPoint);

        timeline.to($letterO2.get(0), { x: 20, y: -22, duration: letterDuration, ease: 'Quad.easeOut' }, startPoint);
        timeline.to($letterO2.get(0), { x: 0, y: 0, duration: letterDuration, ease: 'Quad.easeInOut' }, returnPoint);

        timeline.to($letterO1.get(0), { x: 2, y: -20, duration: letterDuration, ease: 'Quad.easeOut' }, startPoint);
        timeline.to($letterO1.get(0), { x: 0, y: 0, duration: letterDuration, ease: 'Quad.easeInOut' }, returnPoint);

        timeline.to($letterD.get(0), { x: 23, y: -3, duration: letterDuration, ease: 'Quad.easeOut' }, startPoint);
        timeline.to($letterD.get(0), { x: 0, y: 0, duration: letterDuration, ease: 'Quad.easeInOut' }, returnPoint);

        timeline.to($letterS.get(0), { x: 35, y: 10, duration: letterDuration, ease: 'Quad.easeOut' }, startPoint);
        timeline.to($letterS.get(0), { x: 0, y: 0, duration: letterDuration, ease: 'Quad.easeInOut' }, returnPoint);

        timeline.to($letterL.get(0), { x: 16, y: 20, duration: letterDuration, ease: 'Quad.easeOut' }, startPoint);
        timeline.to($letterL.get(0), { x: 0, y: 0, duration: letterDuration, ease: 'Quad.easeInOut' }, returnPoint);

        timeline.to($letterE1.get(0), { x: -18, y: 25, duration: letterDuration, ease: 'Quad.easeOut' }, startPoint);
        timeline.to($letterE1.get(0), { x: 0, y: 0, duration: letterDuration, ease: 'Quad.easeInOut' }, returnPoint);

        timeline.to($letterE2.get(0), { x: 2, y: 23, duration: letterDuration, ease: 'Quad.easeOut' }, startPoint);
        timeline.to($letterE2.get(0), { x: 0, y: 0, duration: letterDuration, ease: 'Quad.easeInOut' }, returnPoint);

        timeline.to($letterF.get(0), { x: -20, y: 1, duration: letterDuration, ease: 'Quad.easeOut' }, startPoint);
        timeline.to($letterF.get(0), { x: 0, y: 0, duration: letterDuration, ease: 'Quad.easeInOut' }, returnPoint);

        rotateTimeline = gsap.timeline({ repeat: -1 });
        rotateTimeline.to($el.get(0), { rotate: '360deg', duration: 1.6, ease: 'Quint.easeInOut' }, 2);

        rotateTimeline.pause();
        timeline.pause();

        if (!skipViewportCheck) {
            checkInViewport();
        } else {
            rotateTimeline.play();
            timeline.play();
            isPlaying = true;
        }

        Viewport.on('resize', onResize);

        if (!skipViewportCheck) {
            Viewport.on('scroll', onScroll);
        }
    };

    const destroy = () => {
        if (timeline) {
            timeline.clear();
        }

        if (rotateTimeline) {
            rotateTimeline.clear();
        }

        Viewport.off('resize', onResize);

        if (!skipViewportCheck) {
            Viewport.off('scroll', onScroll);
        }
    };

    return {
        init,
        destroy
    };
};
