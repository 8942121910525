import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import serialize from 'form-serialize';
import { getAxios, t, nl2br } from "../lib/helpers";

import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const $form = $el.find('form');
    const $response = $el.find('[data-form-response]');

    let isSubmitting = false;
    let errorTimeout = null;

    const submit = () => {
        if (isSubmitting) {
            return;
        }
        
        isSubmitting = true;
        const fields = serialize($form.get(0), { hash: true });
        const client = getAxios();
        
        client
            .post(null, fields)
            .then(res => {
                const { status, data } = res;

                if (status !== 200 || !data) {
                    throw new Error('An error occured. Please try again later.');
                }

                if (!data.success) {
                    throw new Error(data.errorMessage);
                }
                
                $form.find('input').val('');
                showMessage('Subscribed successfully!')

            })
            .catch(error => {
                showMessage(error.message);
            })
            .then(() => {
                isSubmitting = false;
            });
    };

    const showMessage = message => {
        $response.text(message).css({ opacity: 0, display: 'block' });
        gsap.to($response.nodes, { opacity: 1, duration: 0.2 });
        
        clearTimeout(errorTimeout);
        
        errorTimeout = setTimeout(() => {
            gsap.to($response.nodes, { opacity: 0, duration: 0.2 });
        }, 3000);
    };
    
    const init = () => {
        $form.on('submit', e => {
            e.preventDefault();
            submit();
        });
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
