import Vue from 'vue';

import gsap from 'gsap';

export default el => new Vue({
    el,
    delimiters: ['${', '}'], // The only reason to use custom delimiters, is if you want to mix Vue and Twig templates,
    name: 'FAQPanels',
    data() {
        return {
            expanded: []
        };
    },
    methods: {
        isExpanded(id) {
            return this.expanded.indexOf(id) > -1;
        },
        togglePanel(id) {
            if (!this.isExpanded(id)) {
                this.expanded.push(id);
            } else {
                this.expanded.splice(this.expanded.indexOf(id), 1);
            }
        },
        /**
         *
         * Transitions
         *
         */
        onEnter(node, onComplete) {
            gsap.timeline({ onComplete })
                .fromTo(node, 0.3, { opacity: 0 }, { opacity: 1, ease: 'Cubic.easeIn' }, 0)
                .fromTo(node, 0.5, { height: 0 }, { height: 'auto', ease: 'Cubic.easeInOut' }, 0)
                .fromTo(node.firstChild, 0.5, { y: 100 }, { y: 0, ease: 'Cubic.easeOut' }, 0);
        },
        onLeave(node, onComplete) {
            gsap.timeline({ onComplete })
                .to(node, 0.3, { opacity: 0, ease: 'Cubic.easeIn' }, 0)
                .to(node, 0.5, { height: 0, ease: 'Cubic.easeInOut' }, 0)
                .to(node.firstChild, 0.5, { y: 100, ease: 'Cubic.easeIn' }, 0.15);
        }
    }
});
