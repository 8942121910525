import $ from '@vaersaagod/tools/Dom';
import Config from '@vaersaagod/tools/Config';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Parallax from 'parallax-js'

import * as eventKeys from '../lib/events';

export default (el, props) => {

    const $el = $(el);
    
    
    const init = () => {
        
        $el.css({ height: $el.height() });
        
        let items = [];
        
        $el.find('li').each((li) => {
            let $li = $(li);
            let left = $li.position().left;
            let top = $li.position().top;
            let width = $li.width();
            items.push({
                node: $li,
                left: left,
                top: top,
                width: width
            });
            $li.css();
        });
        
        items.forEach((item) => {
            item.node.css({ position: 'absolute', left: item.left, top: item.top, width: item.width });
        });

        const parallaxInstance = new Parallax(el, {
            relativeInput: false,
            pointerEvents: true,
            frictionX: 0.05,
            frictionY: 0.05,
            scalarX: 20,
            scalarY: 20,
            selector: '.product-card'
        });
    };
    
    const destroy = () => {
        
    };

    return {
        init,
        destroy
    };
};
