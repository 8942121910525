import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import CartProduct from '../../vue/CartProduct';
import Vue from 'vue';
import gsap from 'gsap';
import axios from 'axios';
import { getAxios } from "../../lib/helpers";
import serialize from 'form-serialize';

import * as eventKeys from '../../lib/events';

const client = getAxios();

export default (el, props) => new Vue({
    el,
    name: 'AddToCartPopup',
    delimiters: ['${', '}'], // The only reason to use custom delimiters, is if you want to mix Vue and Twig templates
    components: {
        CartProduct
    },
    data() {
        return {
            itemId: 0,
            purchasableId: 0,
            productName: '',
            productUrl: '',
            grindType: '',
            price: 0,
            qty: 0,
            isCoffee: false,
            isVisible: false,
            grindTypes: props && props.grindTypes ? props.grindTypes : [],
        };
    },
    watch: {},
    methods: {
        onAddedProduct(key, data) {
            const purchasableId = data.purchasableId || null;
            const grindType = data.grindType || null;
            const cart = data.cart || null;
            const item = this.getAddedItem(purchasableId, grindType, cart);

            this.purchasableId = data.purchasableId;
            this.grindType = data.grindType;
            this.isCoffee = data.isCoffee;
            
            if (item === null) {
                // todo : Handle error. The added product is not in the cart. Shouldn't happen. :( 
            }

            this.updateItemData(item);
            this.showPopup();
        },

        onCloseClick() {
            this.hidePopup();
        },

        onViewCartClick(e) {
            e.preventDefault();
            this.hidePopup();
            Dispatch.emit(eventKeys.CART_OPEN);
        },

        onMouseEnter() {
            this.clearHideTimeout();
        },

        onMouseLeave() {
            this.startHideTimeout();
        },

        showPopup() {
            let $el = $(this.$el);
            $el.css({ display: 'block' });
            
            gsap.killTweensOf(this.$el);
            gsap.to(this.$el, {
                duration: 1.5, delay: 0.3, top: 0, ease: 'elastic.out(1, 0.4)', onComplete: () => {
                    
                }
            });

            $('body').on('click', e => {
                this.onBodyClick(e);
            });

            this.startHideTimeout(true);
        },
        
        hidePopup() {
            let $el = $(this.$el);
            
            gsap.killTweensOf(this.$el);
            gsap.to(this.$el, {
                duration: 0.2, top: -200, ease: 'sine.in', onComplete: () => {
                    $el.css({ display: 'none' });
                }
            });

            $('body').off('click');
            this.clearHideTimeout();
        },

        setQuantity(itemId, qty) {
            this.qty = qty;

            clearTimeout(this.postTimeout);
            
            if (this.cancelSource !== null) {
                this.cancelSource.cancel();
            }
            
            this.postTimeout = setTimeout(() => {
                this.updateCart();
            }, 300);
        },
        
        removeItem(itemId) {
            // not enabled in popup
        },
        
        setGrindType(itemId, grindType) {
            this.grindType = grindType;
            
            Vue.nextTick(() => {
                this.updateCart();
            });
        },

        updateCart() {
            const data = serialize(this.$refs.form, { hash: true });

            if (this.cancelSource !== null) {
                this.cancelSource.cancel();
            }

            this.cancelSource = axios.CancelToken.source();

            client
                .post(window.location.href, data, { cancelToken: this.cancelSource.token })
                .then(({ data }) => {
                    if (data.success) {
                        const cart = data.cart;
                        const item = this.getAddedItem(this.purchasableId, this.grindType, cart);
                        this.updateItemData(item);

                        Dispatch.emit(eventKeys.CART_UPDATED, {
                            cart: cart
                        });
                    } else {
                        // todo : Handle error, server didn't return success: true.
                    }
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        //console.log('Was cancelled!')
                    } else {
                        console.log(error);
                        // todo : Handle error
                    }
                });
        },

        updateItemData(item) {
            this.itemId = parseInt(item.id || 0);
            this.qty = parseInt(item.qty || 0);
            this.price = parseFloat(item.salePrice || 0);
            this.productName = this.$_get(item, 'snapshot.description', '(N/A)');
            this.productUrl = this.$_get(item, 'snapshot.product.url', '');
        },

        getAddedItem(purchasableId, grindType, cart) {
            const lineItems = cart.lineItems || null;

            if (purchasableId === null || grindType === null || lineItems === null) {
                return null;
            }

            let found = null;

            Object.keys(lineItems).forEach((key, index) => {
                const item = lineItems[key];
                const itemGrindType = this.$_get(item, 'options.grindType', '');
                const itemId = item.purchasableId;
                
                if (itemId === purchasableId && itemGrindType === grindType) {
                    found = item;
                }
            });

            return found;
        },

        onBodyClick(e) {
            const { target } = e;
            if (target !== this.$el && !this.$el.contains(target)) {
                this.hidePopup();
            }
        },

        startHideTimeout(long = false) {
            this.clearHideTimeout();
            this.hideTimeoutId = setTimeout(this.hidePopup, long ? 6000 : 3000);
        },

        clearHideTimeout() {
            if (!this.hideTimeoutId) {
                return;
            }

            clearTimeout(this.hideTimeoutId);
            delete this.hideTimeoutId;
        }
    },

    computed: {},

    mounted() {
        this.cancelSource = null;
        Dispatch.on(eventKeys.CART_ADDED_PRODUCT, this.onAddedProduct);
    },

    destroyed() {
        Dispatch.off(eventKeys.CART_ADDED_PRODUCT, this.onAddedProduct)
    }
});
