import $ from '@vaersaagod/tools/Dom';

import gsap from 'gsap';

export default el => {

    const $el = $(el);

    // Intro animation
    const $contentTop = $el.find('[data-content-top]');
    const $becomeAMember = $contentTop.find('[data-becomeamember]');

    const intro = gsap.timeline({
        paused: true
    })
        .to($contentTop.get(0), { duration: 1.5, y: 0, ease: 'Quint.easeInOut' }, 0)
        //.fromTo($becomeAMember.get(0), 2, { yPercent: 50 }, { yPercent: 0, ease: 'Quint.easeOut' }, 1)
        .fromTo($becomeAMember.get(0), 1, { opacity: 0.0001 }, { opacity: 1 }, 1.8);
    requestAnimationFrame(() => {
        intro.play();
    });
};
