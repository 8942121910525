import Config from '@vaersaagod/tools/Config';
import Dispatch from '@vaersaagod/tools/Dispatch';
import $ from '@vaersaagod/tools/Dom';
import serialize from 'form-serialize';
import Vue from 'vue';
import { getAxios, t, nl2br } from "../lib/helpers";
import * as eventKeys from '../lib/events';

export default (el, props) => new Vue({
    el,
    name: 'MainMenuLoginForm',
    data() {
        return {
            isSubmitting: false,
            hasSubmitted: false,
            error: props.error || null
        };
    },
    methods: {
        /**
         * Form submit handler
         * @param e
         */
        onSubmit(e) {
            e.preventDefault();
            if (this.isSubmitting) {
                return;
            }
            this.isSubmitting = true;
            const fields = {
                loginName: '',
                password: '',
                ...serialize(this.$refs.form, { hash: true })
            };
            const client = getAxios();
            client
                .post(null, fields)
                .then(res => {
                    const { status, data } = res;
                    if (status !== 200 || !data || data.error) {
                        throw new Error(data.error || t('Something went wrong.\nPlease try again later'));
                    }
                    // After logging in, get the user's info
                    return client.get(Config.get('userInformationEndpoint'));
                })
                .then(res => {
                    const { status, data } = res;
                    if (status !== 200 || !data || !data.isLoggedIn) {
                        throw new Error(t('Could not log in.'));
                    }
                    Dispatch.emit(eventKeys.USER_LOGGED_IN, data);
                    this.hasSubmitted = true;
                })
                .catch(error => {
                    this.error = error.message;
                })
                .then(() => {
                    this.isSubmitting = false;
                });
        },
        /**
         * Clear error and values after the menu closes
         */
        onMenuAfterClose() {
            $(this.$el).find('input[name="loginName"],input[name="password"]').each(input => input.value = '');
            this.error = null;
        }
    },
    computed: {
        /**
         * Returns error message with <br> for newlines
         * @returns {null}
         */
        errorMessage() {
            if (!this.error) return null;
            return nl2br(this.error);
        }
    },
    mounted() {
        Dispatch.on(eventKeys.MENU_AFTER_CLOSE, this.onMenuAfterClose);
    },
    destroyed() {
        Dispatch.off(eventKeys.MENU_AFTER_CLOSE, this.onMenuAfterClose);
    }
});
