import axios from 'axios';
import { stringify } from 'qs';
import Config from '@vaersaagod/tools/Config';

import get from 'lodash/get';

const i18n = JSON.parse(Config.get('i18n'));
export const t = (key, vars = {}) => {
    let value = get(i18n, key, key);
    Object.keys(vars).forEach(name => {
        const pattern = `{${name}}`;
        value = value.replace(new RegExp(pattern, 'g'), vars[name]);
    });
    return value;
};

/**
 * Returns an Axios instance with a sensible, default configuration
 * @param config
 * @returns {AxiosInstance}
 */
const { csrfTokenValue } = window;
export const getAxios = (config = {}) => axios.create({
    baseURL: window.location.href || '/',
    headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': csrfTokenValue
    },
    transformRequest: [(data) => stringify(data)],
    ...config
});

/**
 * Initialise a Stripe Elements Card on a DOM node
 *
 * @param node
 * @param opts
 * @returns {Promise<unknown>}
 */
export const initStripeElementsCard = (node, opts = {}) => new Promise((resolve, reject) => {

    const { id } = node;
    if (!id) {
        reject('Card element node has no ID');
    }

    const then = new Date().getTime();

    const doInitCardElement = () => {
        const { stripe } = window;
        if (!stripe) {
            if (new Date().getTime() - then < 2000) {
                requestAnimationFrame(doInitCardElement);
            } else {
                reject('Stripe.js not found');
            }
            return;
        }
        const elements = stripe.elements({
            // fonts: [{
            //     src: `url(https://talormade.test:3000/assets/fonts/mabry-regular-pro.woff2)`,
            //     family: 'mabry-pro'
            // }]
        });
        const card = elements.create('card', {
            hidePostalCode: true,
            style: {
                base: {
                    fontSize: '20px',
                    color: '#733E5C',
                    fontSmoothing: 'antialiased',
                    ':-webkit-autofill': {
                        color: '#733E5C',
                    },
                    '::placeholder': {
                        color: '#733E5C',
                    },
                },
                invalid: {
                    iconColor: '#D61D64',
                    color: '#D61D64',
                },
            },
            ...opts
        });
        card.mount(`#${id}`);
        resolve(card);
    };

    doInitCardElement();

});

/**
 * Convert newlines to <br/>
 *
 * @param str
 */
export const nl2br = str => str ? str.replace(/(?:\r\n|\r|\n|\\r\\n|\\r|\\n)/g, '<br />') : null;

/**
 * Trigger a native event on a generic node/target
 * because we kicked jQuery to the curb
 *
 * @param event
 * @param target
 * @param eventOpts
 */
export const triggerNativeEvent = (event, target, eventOpts = {}) => {
    target = target || window;
    if (typeof (Event) === 'function') {
        // modern browsers
        target.dispatchEvent(new Event(event, { bubbles: true, cancelable: false, ...eventOpts }));
    } else {
        // for IE and other old browsers
        // causes deprecation warning on modern browsers
        const e = target.document.createEvent('UIEvents');
        const { bubbles = true, cancelable = false, detail = 0 } = eventOpts;
        e.initUIEvent(event, bubbles, cancelable, target, detail);
        target.dispatchEvent(e);
    }
};
