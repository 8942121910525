import { notEmpty, isPhone } from "@vaersaagod/tools/validation";
import { t } from './helpers';
import set from 'lodash/set';
import get from 'lodash/get';

const REQUIRED_INPUT_ERROR_TEMPLATE = '{label} cannot be blank.';
const REQUIRED_CHECKBOX_ERROR_TEMPLATE = 'Please tick this box to proceed.';
const REQUIRED_RADIO_ERROR_TEMPLATE = 'Please select an option to proceed.';
const EMAIL_ERROR_TEMPLATE = 'Please add a valid email address.';
const PHONE_ERROR_TEMPLATE = 'Please add a valid phone number.';

// Stolen from https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
// TODO: Find a proper validation library
function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

/**
 *
 * @param form
 * @returns {*}
 */
const validateForm = form => {

    const errors = {};
    const inputs = form.querySelectorAll('input[name]:not([type="hidden"]),select[name]');

    inputs.forEach(input => {
        const error = validateInput(input);
        if (!error) return;
        set(errors, input.name, [].concat(error));
    });

    return Object.keys(errors).length ? errors : null;
};

/**
 *
 * @param input
 * @returns {null|*}
 */
export const validateInput = input => {

    const { id, tagName, type, value, required, dataset } = input;

    const label = get(document.querySelector(`label[for="${id}"]`), 'firstChild.textContent', 'This field');

    const hasValue = notEmpty(value);

    // Is it required?
    const isRequired = !!required || dataset.hasOwnProperty('required') && dataset.required !== 'false';
    if (isRequired) {
        if (type === 'checkbox' && !input.checked) {
            return input.dataset.error || t(REQUIRED_CHECKBOX_ERROR_TEMPLATE, { label });
        }
        if (type === 'radio' && !hasValue) {
            return input.dataset.error || t(REQUIRED_RADIO_ERROR_TEMPLATE, { label });
        }
        if (!hasValue) {
            return input.dataset.error || t(REQUIRED_INPUT_ERROR_TEMPLATE, { label });
        }
    }

    // Return null for non-required fields without a value
    if (!hasValue) {
        return null;
    }

    // Do we need to do additional validation?
    const validationMethod = dataset.validate || type || tagName.toLowerCase();
    switch (validationMethod) {
        case 'email':
            return !validateEmail(value) ? input.dataset.error || t(EMAIL_ERROR_TEMPLATE, { label }) : null;
        case 'phone':
            return !isPhone(value) ? input.dataset.error || t(PHONE_ERROR_TEMPLATE, { label }) : null;
    }

    // All is well, I guess
    return null;

};

export default validateForm;
