import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import gsap from 'gsap';

import { loadPaper } from "../lib/async-bundles";

export default el => {

    const $el = $(el);
    const $shape = $el.find('[data-shape]');
    const $wave = $el.find('[data-wave]');

    const points = 9;
    const maxPathHeight = 0.55;

    // Get the color from the svg path
    const $svg = $shape.find('svg');
    const color = $svg.find('path').css('fill');
    $shape.find('svg').remove();

    // Init the canvas
    const canvas = $shape.find('canvas').get(0);
    $wave.get(0).hidden = false;

    let paper;
    let isPlaying = false;
    let width, height, center;
    let path;
    let pathHeight;
    
    let config = {
        heightSpeed: 0,
        ySpeed: 0,
        sinOffset: 0,
        currentHeightSpeed: 0,
        currentYSpeed: 0,
        currentSinOffset: 0
    };

    const drawPath = () => {
        if (!paper) {
            return;
        }
        center = paper.view.center;
        width = paper.view.size.width;
        height = paper.view.size.height / 2;
        path.segments = [];
        path.add(paper.view.bounds.bottomLeft);
        let x, y;

        for (let i = 1; i < points; ++i) {
            if (i === 1) {
                x = width * 0.02;
                y = height * 1.4;
            } else if (i === 2) {
                x = width * 0.15;
                y = height * 1.4;
            } else if (i === points - 2) {
                x = width * 0.85;
                y = height * 1.7;
            } else if (i === points - 1) {
                x = width * 0.98;
                y = height * 1.35;
            } else {
                x = (width * 0.05) + (width - (width * 0.08)) / (points - 2) * (i - 1);
                y = center.y;
            }
            const point = new paper.Point(x, y);
            path.add(point);
        }

        path.add(paper.view.bounds.bottomRight);
    };
    
    const onScroll = e => {
        checkInViewport();
    };
    
    const checkInViewport = () => {
        if (paper) {
            if (!isPlaying && Viewport.visible($shape.get(0))) {
                paper.view.onFrame = paperFrameHandler;
                isPlaying = true;
            } else if (isPlaying && !Viewport.visible($shape.get(0))) {
                paper.view.onFrame = null;
                isPlaying = false;
            }
        }
    };

    
    
    const paperFrameHandler = event => {
        pathHeight = height * maxPathHeight;

        for (let i = 1; i < points; ++i) {
            const sinSeed = event.count + (i + (i % 3)) * config.sinOffset;
            const sinHeight = (Math.sin(sinSeed / (config.currentHeightSpeed + config.variableHeightSpeed)) * pathHeight);
            const y = (Math.sin(sinSeed / config.ySpeed) * sinHeight) + height;
            if (i <= 1 || i >= points - 1) {
                continue;
            }
            path.segments[i].point.y = y;
        }

        path.smooth({ type: 'geometric', factor: 0.5 });
    };

    const init = () => {
        config.heightSpeed = config.currentHeightSpeed = 100 + Math.round((Math.random() * 20) - 10);
        config.variableHeightSpeed = 0;
        config.ySpeed = 100 + Math.round((Math.random() * 40) - 20);
        config.sinOffset = 180 + Math.round((Math.random() * 40) - 20);
        
        loadPaper(Paper => {
            paper = new Paper.PaperScope();
            paper.setup(canvas);

            // Create the path
            path = new paper.Path();
            path.fillColor = color;
            //path.fullySelected = true;

            // Re-draw the path whenever the size changes
            paper.view.onResize = () => {
                drawPath();
            };

            drawPath();
           
            $el.addClass('js-inited');
            
            $el.on('mouseenter', () => {
                //console.log(config.currentHeightSpeed, config.heightSpeed - 20 - Math.round((Math.random()*20)));
                gsap.to(config, { duration: 1, variableHeightSpeed: 16 - Math.round((Math.random()*32)) });
            });
            $el.on('mouseleave', () => {
                gsap.to(config, { duration: 1, variableHeightSpeed: 0 });
            });
            
            checkInViewport();
            Viewport.on('scroll', onScroll);
        });

    };

    const destroy = () => {
        if (paper) {
            paper.view.onFrame = null;
            paper.view.onResize = null;
            paper.remove();
            paper = null;
        }

        $el.off('mouseenter');
        $el.off('mouseleave');
        
        Viewport.on('scroll', onScroll);
    };

    return {
        init,
        destroy
    };

};
