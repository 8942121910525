import $ from '@vaersaagod/tools/Dom';
import Config from '@vaersaagod/tools/Config';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Vue from 'vue';
import gsap from 'gsap';

import * as eventKeys from '../lib/events';

export default (el, props) => new Vue({
    el,
    delimiters: ['${', '}'], // The only reason to use custom delimiters, is if you want to mix Vue and Twig templates
    data() {
        return {
            logoThreshold: 40,
            logoAreaVisible: true,
            totalQty: props.cartQty || 0
        };
    },
    methods: {
        onCartUpdated(key, data) {
            const { totalQty } = data.cart || {};
            this.totalQty = totalQty; 
        },
        onOpenMenu(e) {
            e.preventDefault();
            Dispatch.emit(eventKeys.TOGGLE_MENU);
        },
        onOpenCart(e) {
            e.preventDefault();
            Dispatch.emit(eventKeys.CART_OPEN);
        },
        onEnter() {
            gsap.fromTo(this.$refs.qtyIndicator, { scale: 0.5, opacity: 0 }, { duration: 1.2, scale: 1, opacity: 1, ease: 'elastic.out(1, 0.5)' });
        },
        onLeave() {
            // not really visible, so...
        },
        onScroll() {
            if (!this.logoAreaVisible && Viewport.scrollTop < this.logoThreshold) {
                this.showLogoArea();
                this.logoAreaVisible = true;
            } else if (this.logoAreaVisible && Viewport.scrollTop > this.logoThreshold) {
                this.hideLogoArea();
                this.logoAreaVisible = false;
            } 
        },
        showLogoArea() {
            gsap.killTweensOf(this.$refs.logoArea);
            gsap.to(this.$refs.logoArea, { duration: 0.4, y: 0, ease: 'expo.out' });
        },
        hideLogoArea() {
            gsap.killTweensOf(this.$refs.logoArea);
            gsap.to(this.$refs.logoArea, { duration: 0.1, y: -100, ease: 'sine.in' });
        },
    },

    mounted() {
        Viewport.on('scroll', this.onScroll);
        this.onScroll();
        Dispatch.on(eventKeys.CART_UPDATED, this.onCartUpdated);
    },

    destroyed() {
        Viewport.off('scroll', this.onScroll);
        Dispatch.off(eventKeys.CART_UPDATED, this.onCartUpdated)
    }
});
