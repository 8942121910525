import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';

import { loadFlickity } from "../lib/async-bundles";

export default (el, props) => {
    const dom = $(el);
    const slider = dom.find('[data-slider]');
    const slides = dom.find('[data-slide]');
    const imageDescriptions = dom.find('[data-image-description]');

    let flkty;
    let resizeTimeout = null;
    let isGrouped = false;
    props = props || {};

    
    const onSlideClick = (event, pointer, cellElement, cellIndex) => {
        if (isGrouped) {
            flkty.selectCell(cellIndex, props.wrapAround || false);
        } else {
            flkty.select(cellIndex, props.wrapAround || false);
        }

    };

    const getTallestImageDescription = () => {
        let tallest = 0;

        imageDescriptions.each((el, i) => {
            let elHeight = $(el).height();

            if (elHeight > tallest) {
                tallest = elHeight;
            }
        });

        return tallest;
    };

    const adjustImageDescriptionArea = () => {
        const adjustHeight = getTallestImageDescription();

        dom.css({ paddingBottom: adjustHeight });
    };

    const checkWrap = () => {
        if (flkty) {
            let slideswidth = 0;

            slides.each((slide) => {
                const $slide = $(slide);
                slideswidth += $slide.width();
            });
            
            flkty.options.wrapAround = slideswidth > Viewport.width * 1.3;
        }
    };

    const onResize = () => {
        adjustImageDescriptionArea();

        if (flkty) {
            checkWrap();

            clearTimeout(resizeTimeout);
            setTimeout(() => {
                flkty.resize();
            }, 100);
        }
    };

    const init = () => {
        loadFlickity(Flickity => {
            flkty = new Flickity.default(slider.get(0), {
                cellSelector: '[data-slide]',
                imagesLoaded: true,
                wrapAround: props.wrapAround || false,
                contain: true,
                pageDots: false,
                prevNextButtons: false,
                draggable: true,
                dragThreshold: 10,
                cellAlign: props.cellAlign || 'center',
                setGallerySize: true,
                percentPosition: true,
                accessibility: true,
                freeScroll: props.freeScroll || false
            });
            
            flkty.on('staticClick', onSlideClick);
            flkty.on('ready', onResize);

            checkWrap();
            adjustImageDescriptionArea();
        });

        imageDescriptions.css({ opacity: 1 });
        adjustImageDescriptionArea();
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        if (flkty) {
            flkty.destroy();
        }
    };

    return {
        init,
        destroy
    };
};
