import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';
import gsap from 'gsap';

import { loadPaper } from "../lib/async-bundles";

import * as eventKeys from '../lib/events';

export default (el, props) => {

    const $el = $(el);

    const { pathHeight: maxPathHeight = 0.75 } = props || {};

    const numPointsByBreakpoint = {
        min: 6,
        m: 8,
        mp: 8,
        l: 12,
        lp: 12,
        xl: 12
    };

    let numPoints = 0;

    // Get the color from the svg path
    const $svg = $el.find('svg');
    const $path = $svg.find('path');
    const color = $path.css('fill');

    // ...then remove the SVG
    $el.find('svg').remove();

    // Init the canvas
    const $canvas = $el.find('canvas');
    const canvas = $canvas.get(0);
    canvas.hidden = false;

    let paper;
    let path;
    let width;
    let height;
    let center;
    let pathHeight;

    let isPlaying = false;
    let menuOpen = false;

    const getNumPoints = () => {
        const { name: breakpoint } = Viewport.breakpoint;
        return numPointsByBreakpoint[breakpoint] || numPointsByBreakpoint.min;
    };

    const drawPath = () => {
        numPoints = getNumPoints();
        console.log({ numPoints });
        center = paper.view.center;
        width = paper.view.size.width;
        height = paper.view.size.height / 2;
        path.segments = [];
        path.add(paper.view.bounds.bottomLeft);
        let x, y;
        for (let i = 1; i < numPoints; ++i) {
            if (i === 1) {
                x = width * 0.08;
                y = height * 0.75;
            } else if (i === numPoints - 1) {
                x = width * 0.95;
                y = height * 0.95;
            } else {
                x = (width * 0.08) + (width - (width * 0.13)) / (numPoints - 2) * (i - 1);
                y = center.y;
            }
            const point = new paper.Point(x, y);
            path.add(point);
        }
        path.add(paper.view.bounds.bottomRight);
        path.smooth({ type: 'continuous' });
    };

    const onScroll = e => {
        checkInViewport();
    };
    
    const onMenuBeforeOpen = e => {
        menuOpen = true;
        paper.view.onFrame = null;
        isPlaying = false;
    };

    const onMenuAfterClose = e => {
        menuOpen = false;
        checkInViewport();
    };

    const checkInViewport = () => {
        if (!menuOpen && paper) {
            if (!isPlaying && Viewport.visible($el.get(0), 100)) {
                paper.view.onFrame = paperFrameHandler;
                isPlaying = true;
            } else if (isPlaying && !Viewport.visible($el.get(0), 100)) {
                paper.view.onFrame = null;
                isPlaying = false;
            }
        }
    };

    const paperFrameHandler = event => {
        pathHeight = height * maxPathHeight;
        path.smooth({ type: 'continuous' });
        for (let i = 1; i < numPoints; ++i) {
            const sinSeed = event.count + (i + i % 10) * 100;
            const sinHeight = Math.sin(sinSeed / 200) * pathHeight;
            if (i !== 1 && i !== numPoints - 1) {
                path.segments[i].point.y = Math.sin(sinSeed / 100) * sinHeight + height;
            }
        }
    };

    const init = () => {

        // Load Paper and create the path
        loadPaper(Paper => {
            paper = new Paper.PaperScope();
            paper.setup(canvas);
            path = new paper.Path();
            path.fillColor = color;

            // Re-draw the path whenever the size changes
            paper.view.onResize = () => {
                drawPath();
            };

            drawPath();

            const intro = gsap.timeline({ paused: true });
            intro.fromTo(el, 1.5, { scaleY: 0.0001 }, { scaleY: 1, transformOrigin: 'left bottom', ease: 'Quart.easeInOut' });

            requestAnimationFrame(() => {
                intro.play();
            });

            checkInViewport();
            Dispatch.on(eventKeys.MENU_BEFORE_OPEN, onMenuBeforeOpen);
            Dispatch.on(eventKeys.MENU_AFTER_CLOSE, onMenuAfterClose);
        });

        Viewport.on('scroll', onScroll);
    };

    const destroy = () => {
        Viewport.off('scroll', onScroll);
        Dispatch.off(eventKeys.MENU_BEFORE_OPEN, onMenuBeforeOpen);
        Dispatch.off(eventKeys.MENU_AFTER_CLOSE, onMenuAfterClose);

        if (paper) {
            paper.view.onFrame = null;
            paper.view.onResize = null;
            paper.remove();
            paper = null;
        }
    };

    return {
        init,
        destroy
    };

};
