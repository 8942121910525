import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';

import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const $link = $el;

    const $arrow1 = $el.find('#become-arrow-1');
    const $arrow2 = $el.find('#become-arrow-2');
    const $arrow3 = $el.find('#become-arrow-3');
    const $arrow4 = $el.find('#become-arrow-4');
    const $arrow5 = $el.find('#become-arrow-5');
    const $letters = $el.find('.become-letter');

    let timeline = null;
    let hoverTimeline = null;
    let isPlaying = false;

    const onResize = e => {

    };

    const onScroll = e => {
        checkInViewport();
    };

    const checkInViewport = () => {
        if (!isPlaying && Viewport.visible($el.get(0))) {
            timeline.play();
            isPlaying = true;
        } else if (isPlaying && !Viewport.visible($el.get(0))) {
            timeline.pause();
            isPlaying = false;
        }
    };

    const createInTimeline = () => {
        if (hoverTimeline) {
            hoverTimeline.kill();
        }

        hoverTimeline = gsap.timeline({ repeat: 0 });
        
        const numLetters = $letters.length;
        
        $letters.each((letter, i) => {
            const offset =  Math.round(i - (numLetters/2));
            
            if (i===6) {
                hoverTimeline.to(letter, {
                    x: 0,
                    y: -10,
                    rotation: 0,
                    scale: 1.2,
                    transformOrigin: 'center center',
                    duration: 2,
                    ease: 'elastic.out(1.2, 0.2)'
                }, 0);
            } else {
                hoverTimeline.to(letter, {
                    x: offset * (4 + Math.random()*3),
                    y: Math.round((Math.random() * 6)  * -offset),
                    rotation: Math.abs(Math.round(offset)) * -(Math.random()*4),
                    scale: 1.0,
                    transformOrigin: 'center center',
                    duration: 1.4,
                    ease: 'elastic.out(1, 0.3)'
                }, 0);
            }

        });

        hoverTimeline.pause();
    };

    const createOutTimeline = () => {
        if (hoverTimeline) {
            hoverTimeline.kill();
        }

        hoverTimeline = gsap.timeline({ repeat: 0 });

        $letters.each((letter, i) => {
            hoverTimeline.to(letter, {
                y: 0,
                x: 0,
                rotation: 0,
                scale: 1,
                //transformOrigin: 'center center',
                duration: 0.3,
                ease: 'Sine.easeOut'
            }, 0);
        });

        hoverTimeline.pause();
    };

    const onMouseEnter = e => {
        createInTimeline();
        hoverTimeline.play();
        timeline.pause();

        gsap.to($arrow1.get(0), { x: 0, y: 0, duration: 0.3, ease: 'Quad.easeOut' });
        gsap.to($arrow2.get(0), { x: 0, y: 0, duration: 0.3, ease: 'Quad.easeOut' });
        gsap.to($arrow3.get(0), { x: 0, y: 0, duration: 0.3, ease: 'Quad.easeOut' });
        gsap.to($arrow4.get(0), { x: 0, y: 0, duration: 0.3, ease: 'Quad.easeOut' });
        gsap.to($arrow5.get(0), { x: 0, y: 0, duration: 0.3, ease: 'Quad.easeOut' });
        
    };

    const onMouseLeave = e => {
        createOutTimeline(true);
        hoverTimeline.play();
        
        timeline.clear();
        createArrowTimeline();
    };
    
    const createArrowTimeline = () => {
        const arrowDuration = 0.8;

        timeline = gsap.timeline({ repeat: -1, yoyo: true });
        timeline.to($arrow1.get(0), { x: 30, y: 30, duration: arrowDuration, ease: 'Quad.easeInOut' }, 0);
        timeline.to($arrow2.get(0), { x: -6, y: 24, duration: arrowDuration, ease: 'Quad.easeInOut' }, 0);
        timeline.to($arrow3.get(0), { x: -35, y: 35, duration: arrowDuration, ease: 'Quad.easeInOut' }, 0);
        timeline.to($arrow4.get(0), { x: -15, y: -30, duration: arrowDuration, ease: 'Quad.easeInOut' }, 0);
        timeline.to($arrow5.get(0), { x: 30, y: -20, duration: arrowDuration, ease: 'Quad.easeInOut' }, 0);
    };

    const init = () => {
        createArrowTimeline();
        checkInViewport();

        $link.on('mouseenter', onMouseEnter);
        $link.on('mouseleave', onMouseLeave);

        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);
    };

    const destroy = () => {
        if (timeline) {
            timeline.clear();
        }
        
        if (hoverTimeline) {
            hoverTimeline.clear();
        }
        
        $link.off('mouseenter', onMouseEnter);
        $link.off('mouseleave', onMouseLeave);
        Viewport.off('resize', onResize);
        Viewport.off('scroll', onScroll);
    };

    return {
        init,
        destroy
    };
};
