<template>
    <div class="cart-product d-f pos-r">
        <div class="cart-product__info flex-11 overflow-h mr-10">
            <p class="t-ellipsis" v-if="productUrl"><a :href="productUrl" class="t-opacity-hover">{{ productName }}</a></p>
            <p class="t-ellipsis" v-else>{{ productName }}</p>
            <p class="t-ellipsis opacity-70">{{ price }} NOK{{ isCoffee ? ' per 250g' : '' }}</p>
            <mini-select v-if="isCoffee"
                    :item-id="itemId" 
                    :grind-type="grindType" 
                    :grind-types="grindTypes"
                    :set-grind-type="setGrindType">
            </mini-select>
            <div v-if="!isCoffee">&nbsp;</div>
        </div>
        <div class="cart-product__qty-wrapper flex-00">
            <div class="d-f ai-c">
                <button class="cart-product__qty-btn -decrease bg-clearpink c-biscay lh-1 fs-16"
                        aria-label="Click to decrease quantity"
                        data-change-quantity-btn="decrease"
                        type="button"
                        v-on:click="onDecrease">
                    <span class="pos-r">–</span>
                </button>

                <input class="fs-24 lh-36 c-white ta-c"
                       v-model="quantity"
                       :name="'lineItems[' + itemId + '][qty]'"
                       type="text"
                       maxlength="2"
                       readonly>

                <button class="cart-product__qty-btn -increase bg-clearpink c-biscay lh-1 fs-16"
                        aria-label="Click to increase quantity"
                        data-change-quantity-btn="increase"
                        v-on:click="onIncrease"
                        type="button">
                    <span class="pos-r">+</span>
                </button>
            </div>
            <button class="pos-a d-ib cart-product__remove-btn t-link" v-if="showRemoveItem" v-on:click="onRemoveItemClick" type="button">Remove</button>
            <input type="hidden" :name="'lineItems[' + itemId + '][remove]'" :value="remove">
        </div>
    </div>
</template>

<script>
    import MiniSelect from '../vue/MiniSelect';
    
    export default {
        name: "CartProduct.vue",
        props: {
            itemId: Number,
            price: Number,
            productName: String,
            productUrl: String,
            grindType: String,
            grindTypes: Object,
            quantity: Number,
            isCoffee: Boolean,
            showRemoveItem: Boolean,
            setQuantity: Function,
            removeItem: Function,
            setGrindType: Function,
        },
        components: { MiniSelect },
        computed: {},
        data: function () {
            return {
                remove: '',
            }
        },
        methods: {
            onDecrease() {
                this.setQuantity(this.itemId, this.quantity > 1 ? this.quantity - 1 : this.quantity);
            },
            onIncrease() {
                this.setQuantity(this.itemId, this.quantity < 99 ? this.quantity + 1 : this.quantity);
            },
            onRemoveItemClick() {
                this.remove = '1';
                this.removeItem();
            }
        },
        mounted() {

        }
    }
</script>
