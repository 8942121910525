import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import ResizeObserver from "resize-observer-polyfill";

import { loadPaper } from "./async-bundles";

import gsap from 'gsap';

const DEFAULT_SPEED = 0.5;
const DEFAULT_POINT_STAGGER = 0.2;
const DEFAULT_COLOR = '#19A786';

const DIRECTION_RIGHT = 'right'; // Move left to right
const DIRECTION_LEFT = 'left'; // Move right to left
const DIRECTION_DOWN = 'up'; // Move bottom to top
const DIRECTION_UP = 'down'; // Move top to bottom

export default function (canvas, opts = {}) {

    const $canvas = $(canvas);

    const {
        container = null,
        direction = DIRECTION_RIGHT,
        speed = DEFAULT_SPEED,
        adaptiveDuration = true,
        onComplete,
        onReverseComplete,
        onReady
    } = opts || {};

    let { sizeElement, color = DEFAULT_COLOR } = opts || {};
    sizeElement = sizeElement || container;

    if ([DIRECTION_RIGHT, DIRECTION_LEFT, DIRECTION_DOWN, DIRECTION_UP].indexOf(direction) === -1) {
        throw new Error(`Invalid direction "${direction}"`);
    }

    const getWidth = () => (sizeElement ? sizeElement.getBoundingClientRect() : Viewport).width;

    const getHeight = () => (sizeElement ? sizeElement.getBoundingClientRect() : Viewport).height;

    let width = getWidth(),
        height = getHeight(),
        isWiped = false,
        paper,
        path,
        timeline,
        resizeObserver;

    /**
     * Returns the initial (start) bounds for our path
     * @returns {{x: number, width: number, y: number, height: number}}
     */
    const getInitialBounds = () => {
        switch (direction) {
            case DIRECTION_RIGHT:
                return { x: width, y: 0, width: 0, height };
            case DIRECTION_LEFT:
                return { x: 0, y: 0, width: 0, height };
            case DIRECTION_DOWN:
                return { x: 0, y: height, width, height: 0 };
            case DIRECTION_UP:
                return { x: 0, y: 0, width, height: 0 };
        }
    };

    /**
     * Returns the final (end) bounds for our path
     * @returns {{x: number, width: number, y: number, height: number}}
     */
    const getFinalBounds = () => {
        switch (direction) {
            case DIRECTION_RIGHT:
                return { x: 0, y: 0, width, height };
            case DIRECTION_LEFT:
                return { x: 0, y: 0, width, height };
            case DIRECTION_DOWN:
                return { x: 0, y: 0, width, height };
            case DIRECTION_UP:
                return { x: 0, y: 0, width, height };
        }
    };

    /**
     * Returns an array of randomized points for the supplied bounds
     * @param bounds
     * @param pointDistance
     * @returns {[]}
     */
    const getPointsForBounds = (bounds, pointDistance = 0.25) => {

        let points = [[bounds.x, bounds.y]];

        let x = 0,
            y = 0,
            part = 'top',
            done;

        while (!done) {

            if (part === 'top') {
                x += pointDistance;
                if (x > 1) {
                    x = 1;
                    part = 'right';
                }
            } else if (part === 'right') {
                y += pointDistance;
                if (y > 1) {
                    y = 1;
                    part = 'bottom';
                }
            } else if (part === 'bottom') {
                x -= pointDistance;
                if (x < 0) {
                    x = 0;
                    part = 'left';
                }
            } else if (part === 'left') {
                y -= pointDistance;
                if (y < 0.1) {
                    done = true;
                    break;
                }
            }

            x = parseFloat(x.toFixed(2));
            y = parseFloat(y.toFixed(2));

            points.push([bounds.x + x * bounds.width, bounds.y + y * bounds.height]);
        }

        points = points.map(point => {
            if (point[0] <= 0) {
                point[0] = 0.0001;
            }
            if (point[1] <= 0) {
                point[1] = 0.0001;
            }
            return point;
        });

        return points;
    };

    /**
     *
     * @param points
     * @param onComplete
     */
    const createSegmentsTimeline = (points, onComplete = null) => {

        let prevProgress = 1;
        if (timeline) {
            if (adaptiveDuration === true) prevProgress = timeline.progress();
            timeline.kill();
        }

        timeline = gsap.timeline({
            onUpdate: render,
            onComplete() {
                timeline.kill();
                timeline = null;
                if (onComplete) {
                    onComplete();
                }
            }
        });

        // Calculate duration by multiplying w/ the progress of the previous tween, to ensure smooth playback if the wipe changes direction mid-animation
        const duration = speed * prevProgress;

        path.segments.forEach((segment, i) => {
            const delay = Math.random() * (duration * DEFAULT_POINT_STAGGER);
            timeline.to(segment.point, duration, {
                x: points[i][0],
                y: points[i][1],
                ease: 'Quad.easeInOut'
            }, delay);
        });
    };

    /**
     * Render the canvas
     */
    const render = () => {
        if (!paper || !path) {
            return;
        }
        canvas.width = width;
        canvas.height = height;
        path.smooth({ type: 'catmull-rom' });
        paper.view.update();
    };

    /**
     *  Resize the canvas and the drawn path
     */
    const resize = () => {

        width = getWidth();
        height = getHeight();

        canvas.width = width;
        canvas.height = height;

        $canvas.css({ width, height });

        // ...then, recreate the path with fresh bounds. Account for running animation.
        if (!path) {
            return;
        }

        let isAnimating = !!timeline;
        let fromBounds;
        let endBounds;
        let callback;

        if (isWiped) {
            fromBounds = getInitialBounds();
            endBounds = getFinalBounds();
            callback = onComplete;
        } else {
            fromBounds = getFinalBounds();
            endBounds = getInitialBounds();
            callback = onReverseComplete;
        }

        if (!isAnimating) {

            // Not animating - just update the segments and done.
            const points = getPointsForBounds(endBounds);
            path.segments.forEach((segment, i) => {
                gsap.set(segment.point, {
                    x: points[i][0],
                    y: points[i][1]
                });
            });

            render();

        } else {

            // We're animating so we need to do a lot more work :(
            const progress = timeline.progress();
            const initialPoints = getPointsForBounds(fromBounds);

            const delays = timeline.getChildren().map(tween => tween._start);

            timeline.kill();
            timeline = null;

            path.segments.forEach((segment, i) => {
                gsap.set(segment.point, {
                    x: initialPoints[i][0],
                    y: initialPoints[i][1]
                });
            });

            const toPoints = getPointsForBounds(endBounds);

            // Create a new timeline that adds all the original delays to get a mostly identical timeline, then set the original timeline's progress to it
            // There will be a shift in the form of the visible shape on the screen mid-animation if the screen resizes, but c'mon
            timeline = gsap.timeline({
                onUpdate: render,
                onComplete() {
                    timeline.kill();
                    timeline = null;
                    if (callback) callback();
                }
            });

            path.segments.forEach((segment, i) => {
                timeline
                    .to(segment.point, {
                        duration: speed,
                        x: toPoints[i][0],
                        y: toPoints[i][1]
                    }, delays[i]);
            });

            timeline.progress(progress);
        }

        requestAnimationFrame(render);

    };

    const wipe = (tween = true, suppressCallbacks = false) => {

        if (isWiped) return;

        isWiped = true;

        if (!path) {
            return;
        }

        const points = getPointsForBounds(getFinalBounds());
        createSegmentsTimeline(points, !suppressCallbacks ? onComplete : null);

        if (!tween) {
            timeline.pause(timeline.totalDuration(), false);
        }

    };

    const reverseWipe = (tween = true, suppressCallbacks = false) => {

        if (!isWiped) return;

        isWiped = false;

        if (!path) {
            return;
        }

        const points = getPointsForBounds(getInitialBounds());
        createSegmentsTimeline(points, !suppressCallbacks ? onReverseComplete : null);

        if (!tween) {
            timeline.pause(timeline.totalDuration(), false);
        }

    };

    /**
     * Toggle the wipe open/close
     */
    const toggleWipe = () => {
        if (!isWiped) {
            wipe();
        } else {
            reverseWipe();
        }
    };

    /**
     *
     * @param newColor
     */
    const setColor = newColor => {
        color = newColor;
        if (!path) {
            return;
        }
        path.fillColor = new paper.Color(color);
        render();
    };

    /**
     *
     * @returns {*}
     */
    const getTimeline = () => timeline;

    /**
     *
     */
    const init = () => {

        resize();

        loadPaper(Paper => {

            // Create the Paper instance
            paper = new Paper.PaperScope();
            paper.setup(canvas);
            paper.view.autoUpdate = false;

            // Create the path
            path = new paper.Path();
            path.fillColor = new paper.Color(color);
            path.closed = true;
            const points = getPointsForBounds(getInitialBounds());
            for (let i = 0; i < points.length; i++) {
                path.add(new paper.Point(points[i][0], points[i][1]));
            }

            if (isWiped) {
                wipe(false);
            }

            resize();

            if (onReady) {
                onReady();
            }

        });

        if (sizeElement) {
            resizeObserver = new ResizeObserver(resize);
            resizeObserver.observe(sizeElement);
        } else {
            Viewport.on('resize', resize);
        }

    };

    /**
     *
     */
    const destroy = () => {

        if (timeline) {
            timeline.kill();
            timeline = null;
        }

        if (resizeObserver) {
            resizeObserver.disconnect();
            resizeObserver = null;
        } else {
            Viewport.off('resize', resize);
        }

        if (paper) {
            paper.remove();
            paper = null;
        }

    };

    init();

    return {
        wipe,
        reverse: reverseWipe,
        toggle: toggleWipe,
        setColor: setColor,
        getTimeline,
        destroy
    };

};
