/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */

export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const CART_OPEN = 'CART_OPEN';
export const CART_UPDATED = 'CART_UPDATED';
export const CART_ADDED_PRODUCT = 'CART_ADDED_PRODUCT';

export const NEW_SUBSCRIPTION_UPDATED = 'NEW_SUBSCRIPTION_UPDATED';

export const ADD_ERROR_ALERT = 'ADD_ERROR_ALERT';
export const ADD_NOTICE_ALERT = 'ADD_NOTICE_ALERT';

export const MENU_BEFORE_OPEN = 'MENU_BEFORE_OPEN';
export const MENU_AFTER_OPEN = 'MENU_AFTER_OPEN';
export const MENU_BEFORE_CLOSE = 'MENU_BEFORE_CLOSE';
export const MENU_AFTER_CLOSE = 'MENU_AFTER_CLOSE';
