import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';

import { loadFlickity } from "../lib/async-bundles";

export default el => {
    const dom = $(el);
    const slider = dom.find('[data-slider]');
    const slides = dom.find('[data-slide]');
    
    let flkty;
    let resizeTimeout = null;

    const checkWrap = () => {
        if (flkty) {
            let slideswidth = 0;

            slides.each((slide) => {
                const $slide = $(slide);
                slideswidth += $slide.width();
            });
            
            flkty.options.wrapAround = slideswidth > Viewport.width * 1.3;
        }
    };
    
    const onResize = () => {
        if (flkty) {
            checkWrap();

            clearTimeout(resizeTimeout);
            setTimeout(() => {
                flkty.resize();
            }, 100);
        }
    };

    const init = () => {
        
        loadFlickity(Flickity => {
            flkty = new Flickity.default(slider.get(0), {
                cellSelector: '[data-slide]',
                imagesLoaded: true,
                wrapAround: true,
                contain: true,
                pageDots: false,
                prevNextButtons: false,
                draggable: true,
                dragThreshold: 10,
                cellAlign: 'center',
                setGallerySize: true,
                percentPosition: true,
                accessibility: true,
                freeScroll: true
            });

            flkty.on('ready', onResize);
            
            checkWrap();
        });

        Viewport.on('resize', onResize);
        
    };

    const destroy = () => {
        if (flkty) {
            flkty.destroy();
        }
    };

    return {
        init,
        destroy
    };
};
