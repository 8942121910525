import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Config from '@vaersaagod/tools/Config';
import CartProduct from '../../vue/CartProduct';
import Vue from 'vue';
import gsap from 'gsap';
import axios from 'axios';
import { getAxios } from "../../lib/helpers";
import serialize from 'form-serialize';

import * as eventKeys from '../../lib/events';

const client = getAxios();

export default (el, props) => new Vue({
    el,
    name: 'CartContents',
    delimiters: ['${', '}'], // The only reason to use custom delimiters, is if you want to mix Vue and Twig templates
    components: {
        CartProduct
    },
    data() {
        return {
            shippingAddress: null,
            subtotal: 0,
            total: 0,
            totalTax: 0,
            totalTaxIncl: 0,
            totalShippingCost: 0,
            totalDiscount: 0,
            items: [],
            grindTypes: props && props.grindTypes ? props.grindTypes : []
        };
    },
    watch: {},
    methods: {
        onCartUpdated(key, data) {
            const { itemSubtotal, total, totalTax, totalTaxIncluded, totalShippingCost, totalDiscount, lineItems, shippingAddress } = data.cart || {};
            
            this.shippingAddress = shippingAddress;
            this.subtotal = itemSubtotal; 
            this.total = total; 
            this.totalTax = totalTax; 
            this.totalTaxIncl = totalTaxIncluded; 
            this.totalShippingCost = totalShippingCost;
            this.totalDiscount = totalDiscount;
            this.items = this.parseLineItems(lineItems);
            
            
        },
        
        parseLineItems(lineItems) {
            let items = [];
            
            Object.keys(lineItems).forEach((key, index) => {
                const lineItem = lineItems[key];
                
                let item = {
                    itemId: parseInt(lineItem.id || 0),
                    purchasableId: parseInt(lineItem.purchasableId || 0),
                    qty: parseInt(lineItem.qty) || 0,
                    price: parseFloat(lineItem.salePrice) || 0,
                    grindType: this.$_get(lineItem, 'options.grindType', ''),
                    productName: this.$_get(lineItem, 'snapshot.description', '(N/A)'),
                    productUrl: this.$_get(lineItem, 'snapshot.product.url', ''),
                    typeId: this.$_get(lineItem, 'snapshot.product.typeId', null)
                };

                items.push(item);
            });
            
            return items;
        },
        
        setQuantity(itemId, qty) {
            this.updateItemQuantity(parseInt(itemId), parseInt(qty));
            
            clearTimeout(this.postTimeout);
            
            if (this.cancelSource !== null) {
                this.cancelSource.cancel();
            }
            
            this.postTimeout = setTimeout(() => {
                this.updateCart();
            }, 300);
        },
        
        updateItemQuantity(itemId, qty) {
            for (let i=0; i<this.items.length; i++) {
                if (this.items[i].itemId === itemId) {
                    this.items[i].qty = qty;
                }
            }
        },
        
        removeItem() {
            // todo : Should maybe update the items array instantly?

            Vue.nextTick(() => {
                this.updateCart();
            });
        },
        
        setGrindType(itemId, grindType) {
            // todo : Should maybe update the items array too? Doesn't really work since two lines could collapse.
            
            Vue.nextTick(() => {
                this.updateCart();
            });
        },

        updateCart() {
            const data = serialize(this.$refs.form, { hash: true });

            if (this.cancelSource !== null) {
                this.cancelSource.cancel();
            }

            this.cancelSource = axios.CancelToken.source();

            client
                .post(window.location.href, data, { cancelToken: this.cancelSource.token })
                .then(({ data }) => {
                    if (data.success) {
                        const cart = data.cart;

                        Dispatch.emit(eventKeys.CART_UPDATED, {
                            cart: cart
                        });
                    } else {
                        // todo : Handle error, server didn't return success: true.
                    }
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        //console.log('Was cancelled!')
                    } else {
                        console.log(error);
                        // todo : Handle error
                    }
                });
        },
        
        getCart() {
            client
                .get(Config.get('getCartEndpoint'))
                .then(({ data }) => {
                    if (data.cart) {
                        const cart = data.cart;

                        Dispatch.emit(eventKeys.CART_UPDATED, {
                            cart: cart
                        });
                    } else {
                        // todo : Handle error, server didn't return success: true.
                    }
                })
                .catch(error => {
                    console.log(error);
                    // todo : Handle error
                });
        }
        
    },

    computed: {
        isTaxable() {
            if (!this.shippingAddress) {
                return true;
            }
            
            return parseInt(this.shippingAddress.countryId) === 167;
        }
    },

    mounted() {
        this.cancelSource = null;
        Dispatch.on(eventKeys.CART_UPDATED, this.onCartUpdated);
        
        this.getCart();
    },

    destroyed() {
        Dispatch.off(eventKeys.CART_UPDATED, this.onCartUpdated)
    }
});
