import Vue from 'vue';
import { validateInput } from "../../lib/validate";
import set from "lodash/set";
import omit from "lodash/omit";
import find from 'lodash/find';
import $ from "@vaersaagod/tools/Dom";
import Dispatch from "@vaersaagod/tools/Dispatch";
import { ADD_ERROR_ALERT } from "../../lib/events";
import { getAxios, t } from "../../lib/helpers";

import StateInput from "../../vue/StateInput";

import gsap from 'gsap';
import { confirmCardSetup, initCardElement } from "../../lib/stripeCardElementFacade";
import serialize from "form-serialize";

const VALIDATION_ERROR_ALERT = t('ERROR_MESSAGE_VALIDATION');
const SERVER_ERROR_ALERT = t('ERROR_MESSAGE_SERVER');

const ERROR_ALERT_DURATION = 5;

export default (el, props) => new Vue({
    el,
    delimiters: ['${', '}'], // The only reason to use custom delimiters, is if you want to mix Vue and Twig templates,
    name: 'EditPayment',
    components: {
        StateInput
    },
    data() {
        return {
            paymentMethodId: props.paymentMethodId,
            nameOnCard: null,
            billingAddress: {
                firstName: null,
                lastName: null,
                address1: null,
                address2: null,
                city: null,
                countryId: '',
                stateId: '',
                stateName: '',
                zipCode: null
            },
            email: props.email,
            hasEnteredCardDetails: false,
            makeMembershipPaymentSource: false,
            isSubmitting: false,
            hasSubmitted: false,
            errors: null,
            errorMessage: null,
            formEnabled: !!props.paymentMethodId
        };
    },
    computed: {
        countryId() {
            return this.billingAddress.countryId;
        },
        /**
         *
         * @returns {boolean}
         */
        hasErrors() {
            return this.errors && Object.keys(this.errors).length;
        },
        /**
         *
         * @returns {{address: {country: (*|null), city: (null|computed.address.city|string), state: null, postal_code: null, line1: null}, name: null}}
         */
        billingDetails() {
            const name = [this.billingAddress.firstName, this.billingAddress.lastName].filter(i => !!i).join(' ');
            const { stateId, stateName } = this.billingAddress;
            let state = null;
            if (stateId) {
                state = find(props.states, { id: stateId.toString() }, null);
                state = state && state.name ? state.name : null;
            } else if (stateName) {
                state = stateName;
            }
            return {
                name,
                email: this.email,
                address: {
                    city: this.billingAddress.city,
                    country: (find(props.countryIsos, { id: this.billingAddress.countryId }) || {}).iso || null,
                    line1: this.billingAddress.address1,
                    line2: this.billingAddress.address2,
                    postal_code: this.billingAddress.zipCode,
                    state
                }
            };
        }
    },
    watch: {
        countryId() {
            // Wipe the state name and state ID when the country changes
            this.billingAddress = {
                ...this.billingAddress,
                stateName: '',
                stateId: ''
            };
        },
        errors() {
            if (this.hasErrors) {
                // Focus to first input with an error
                Vue.nextTick(() => {
                    $('html').addClass('outline').removeClass('no-outline');
                    const firstErrorInput = this.$refs.form.querySelector('[data-haserror]');
                    if (!firstErrorInput && this.card && !!this.errors.paymentMethodId) {
                        this.card.focus();
                    } else if (firstErrorInput) {
                        try {
                            firstErrorInput.focus();
                        } catch (error) {
                            // Don't care.
                        }
                    }
                });
            }
        },
        errorMessage(message) {
            if (!message) {
                return null;
            }
            Dispatch.emit(ADD_ERROR_ALERT, {
                message,
                duration: ERROR_ALERT_DURATION
            });
            this.errorMessage = null;
        }
    },
    methods: {

        addError(key, error) {
            this.errors = set({
                ...(this.errors || {})
            }, key, error);
        },

        clearError(key) {
            if (!this.errors || !this.$_get(this.errors, key)) {
                return;
            }
            this.errors = omit(this.errors, key);
        },

        /**
         * Validate the form
         *
         * @returns {boolean}
         */
        validateForm() {
            let hasErrors = false;
            const inputs = this.$refs.form.querySelectorAll('input[name]:not([type="hidden"]),select[name]');
            inputs.forEach(input => {
                const error = validateInput(input);
                if (!error) {
                    this.clearError(input.name);
                    return;
                }
                this.addError(input.name, error);
                hasErrors = true;
            });
            return !hasErrors;
        },

        submitForm() {

            if (!this.formEnabled || this.isSubmitting || this.hasSubmitted) {
                return;
            }

            // Validate form
            let formIsValid = this.validateForm();

            // Extra validation - credit card
            if (!this.paymentMethodId && !this.hasEnteredCardDetails) {
                this.addError('paymentMethodId', t('Your card number is incomplete.'));
                formIsValid = false;
            } else {
                this.clearError('paymentMethodId');
            }

            // Eject if form contains errors
            if (!formIsValid) {
                this.errorMessage = VALIDATION_ERROR_ALERT;
                return;
            }

            this.isSubmitting = true;

            if (!this.paymentMethodId) {
                // No payment method ID found – confirm card setup w/ Stripe
                confirmCardSetup(this.card, this.billingDetails)
                    .then(paymentMethodId => {
                        this.clearError('paymentMethodId');
                        this.paymentMethodId = paymentMethodId;
                    })
                    .catch(error => {
                        this.addError('paymentMethodId', error.message || error);
                        this.errorMessage = VALIDATION_ERROR_ALERT;
                        this.card.focus();
                    })
                    .then(() => {
                        this.isSubmitting = false;
                        Vue.nextTick(() => {
                            if (!this.paymentMethodId) {
                                return;
                            }
                            this.submitForm();
                        });
                    });
                return;
            }

            // Finally, actually submit the form.
            const client = getAxios();
            const data = serialize(this.$refs.form, { hash: true });

            client
                .post(null, data)
                .then(({ status, data }) => {
                    if (status !== 200) {
                        throw new Error();
                    }
                    const { errors = null, errorMessage = null, redirect } = data;
                    if (errors) {
                        this.errors = errors;
                    }
                    if (errorMessage) {
                        this.errorMessage = errorMessage;
                    }
                    if (!errors && !errorMessage) {
                        this.hasSubmitted = true;
                        window.location.href = `/${redirect}`;
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.errorMessage = SERVER_ERROR_ALERT;
                })
                .then(() => {
                    this.isSubmitting = false;
                });

        },

        /**
         * --------- Event handlers --------
         */

        onFormSubmit(e) {
            e.preventDefault();
            this.submitForm();
        },

        onCardChange(e) {
            // Clear out the payment method ID whenever the card input changes
            // This forces us to create and confirm a new SetupIntent if the user goes back and changes the card details after we already confirmed it
            this.hasEnteredCardDetails = !e.empty;
            this.paymentMethodId = null;
        },

        /**
         *
         * ----- Transitions
         *
         */
        onInfoEnter(node, onComplete) {
            gsap
                .timeline({ onComplete })
                .to(node, 0.5, { height: 'auto', ease: 'Cubic.easeOut' }, 0)
                .fromTo(node.firstChild, 0.3, { opacity: 0 }, { opacity: 1, ease: 'Cubic.easeIn' }, 0)
                .fromTo(node.firstChild, 0.5, { y: 20 }, { y: 0, ease: 'Cubic.easeOut' }, 0);
        },

        onInfoLeave(node, onComplete) {
            gsap
                .timeline({ onComplete })
                .to(node, 0.35, { height: 0, ease: 'Cubic.easeInOut' }, 0)
                .to(node.firstChild, 0.2, { opacity: 0, ease: 'Cubic.easeIn' }, 0)
                .to(node.firstChild, 0.3, { y: -10, ease: 'Cubic.easeIn' }, 0);
        }
    },

    mounted() {
        // Initialize the Stripe Elements card
        const cardNode = this.$refs.creditcard;
        if (cardNode) {
            initCardElement(cardNode)
                .then(card => {
                    this.card = card;
                    this.card.addEventListener('change', this.onCardChange);
                    this.formEnabled = true;
                })
                .catch(error => {
                    console.error(error);
                    this.addError('paymentMethodId', error.message || error);
                });
        }
    },
    destroyed() {
        if (this.card) {
            this.card.removeEventListener('change', this.onCardChange);
        }
    }
});
