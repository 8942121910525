import Vue from 'vue';
import Viewport from "@vaersaagod/tools/Viewport";
import ResizeObserver from "resize-observer-polyfill";
import $ from '@vaersaagod/tools/Dom';
import Components from '@vaersaagod/tools/Components';
import random from 'lodash/random';
import gsap from 'gsap';

import { getAxios } from "../../lib/helpers";
import WaveWipe from "../../lib/WaveWipe";
import serialize from "form-serialize";

export default el => new Vue({
    el,
    name: 'Quiz',
    delimiters: ['${', '}'], // The only reason to use custom delimiters, is if you want to mix Vue and Twig templates
    data: {
        questions: [],
        answers: {},
        direction: 'forward',
        isSubmitting: false,
        hasSubmitted: false,
        errorMessage: null
    },
    watch: {
        questions(newQuestions, oldQuestions) {
            const removedQuestions = oldQuestions.filter(question => newQuestions.indexOf(question) === -1);
            removedQuestions.forEach(question => {
                delete this.answers[question];
                const index = oldQuestions.indexOf(question);
                oldQuestions.slice(index, oldQuestions.length).forEach(question => {
                    delete this.answers[question];
                });
            });
        },
        answers: {
            deep: true,
            handler() {
                Vue.nextTick(this.updateQuestions);
            }
        },
        currentQuestionIndex(newValue, oldValue) {
            this.direction = newValue > oldValue ? 'forward' : 'backward';
        },
        quizComplete() {
            setTimeout(() => {
                const { submitBtn } = this.$refs;
                if (this.quizComplete) {
                    gsap.to(submitBtn, { duration: 0.5, height: 160, ease: 'Back.easeOut' });
                    gsap.to(submitBtn.firstChild, {
                        duration: 0.3,
                        scale: 1.5,
                        ease: 'Cubic.easeInOut'
                    });
                } else {
                    gsap.to(submitBtn, {
                        duration: 0.5,
                        height: 'auto',
                        ease: 'Back.easeInOut',
                        clearProps: 'height'
                    });
                    gsap.to(submitBtn.firstChild, {
                        duration: 0.3,
                        scale: 1,
                        ease: 'Cubic.easeInOut',
                        clearProps: 'scale'
                    });
                }
                this.scrollToBottom();
            }, 0);
        }
    },
    computed: {
        activeQuestions() {
            // The active (visible) questions are all questions that have been answered, plus the first unanswered one
            const questions = this.questions;
            const answers = Object.keys(this.answers).filter(key => !!this.answers[key]);
            const activeQuestions = [];
            for (let i = 0; i < questions.length; ++i) {
                activeQuestions.push(questions[i]);
                if (answers.indexOf(questions[i]) === -1) {
                    break;
                }
            }
            return activeQuestions;
        },
        currentQuestion() {
            const activeQuestions = this.activeQuestions;
            return activeQuestions[activeQuestions.length - 1];
        },
        currentQuestionIndex() {
            return this.questions.indexOf(this.currentQuestion);
        },
        quizComplete() {
            // The quiz is complete when all rendered questions have answers
            const questions = this.activeQuestions;
            for (let i = 0; i < questions.length; ++i) {
                if (!this.answers[questions[i]]) {
                    return false;
                }
            }
            return true;
        }
    },
    methods: {

        scrollToBottom() {

            if ((!this.quizComplete && this.direction === 'backward') || this.isSubmitting || this.hasSubmitted) {
                return;
            }

            const viewportHeight = Viewport.height;
            const bodyHeight = $('body').height();

            let scrollTo = bodyHeight - viewportHeight;

            if (!this.quizComplete) {

                const { questions } = this.$refs;
                const currentQuestion = $(questions).find(`[data-question="${this.currentQuestion}"]`).get(0);

                const { top } = currentQuestion.getBoundingClientRect();
                if (top <= 100) {
                    scrollTo = $(currentQuestion).offset().top - 100;
                }

            }

            gsap.set(window, { scrollTo: { y: scrollTo } });

        },

        /**
         *
         */
        updateQuestions() {
            this.questions = $(this.$refs.questions).find('[data-question].is-active').get().map(question => question.dataset.question);
        },

        submit() {

            if (this.isSubmitting || this.hasSubmitted) {
                return;
            }

            this.isSubmitting = true;

            // Show outro animation
            const { quizFooter, submitBtn, goodFeels, wave, questions, breadcrumbs } = this.$refs;
            let color;

            if (this.quizComplete) {
                color = $(submitBtn).css('backgroundColor');
            } else {
                color = $(quizFooter).css('backgroundColor');
            }

            wave.hidden = false;
            goodFeels.hidden = false;

            this.wave.setColor(color);
            this.wave.wipe();

            const waveDuration = this.wave.getTimeline().totalDuration();

            gsap.timeline({
                onComplete: () => {
                    // Submit the form
                    const client = getAxios({ baseURL: '/' });
                    const data = serialize(this.$refs.form, { hash: true });

                    client
                        .post(null, data)
                        .then(({ status, data }) => {
                            if (status !== 200 || !data || !data.success) {
                                throw new Error();
                            }
                            this.hasSubmitted = true;
                            return data;
                        })
                        .then(({ redirect }) => {
                            client
                                .get(redirect)
                                .then(({ status, data: html }) => {
                                    if (status !== 200 || !html) {
                                        throw new Error();
                                    }
                                    setTimeout(() => {
                                        if (window.history) {
                                            window.history.pushState({ url: redirect }, null, redirect);
                                        }
                                        this.finishSubmit(html);
                                    }, random(750, 1500, false));
                                })
                                .catch(error => {
                                    console.error(error);
                                });
                        })
                        .catch(error => {
                            console.error(error);
                        })
                        .then(() => {
                            this.isSubmitting = false;
                        });
                }
            })
                .to(submitBtn.firstChild, 0.3, { opacity: 0 }, 0)
                .to([questions, breadcrumbs], waveDuration, { y: -(Viewport.height * 0.75), ease: 'Quad.easeInOut' }, 0.1)
                .fromTo(goodFeels, waveDuration, { yPercent: 100 }, {
                    yPercent: 0,
                    ease: 'Quad.easeInOut'
                }, 0);
        },

        finishSubmit(html) {

            const { wave, goodFeels, formContainer, resultsContainer, quizFooter } = this.$refs;

            const $html = $('<div/>').html(html);

            gsap.set(wave, { scaleY: -1 });
            const $resultsContainer = $(resultsContainer);
            $resultsContainer
                .html($html.find('#main').html())
                .css({
                    opacity: 0.001
                });
            resultsContainer.hidden = false;
            Components.init($resultsContainer);

            $(quizFooter).css({ height: 0, minHeight: 'none' });
            quizFooter.hidden = true;

            $(formContainer).css({ opacity: 0 });

            requestAnimationFrame(() => {
                gsap.set(window, { scrollTo: { y: 0 } });
            });

            setTimeout(() => {

                this.wave.reverse();
                const waveDuration = this.wave.getTimeline().totalDuration();

                gsap.timeline({
                    onComplete: () => {
                        this.destroy();
                        $(wave).remove();
                        $(goodFeels).remove();
                        $(formContainer).remove();
                        $(this.$el).css({
                            paddingBottom: '0px'
                        });
                        $resultsContainer.removeClass('pos-f u-fill');
                        gsap.set(window, { scrollTo: { y: 0 } });
                    }
                })
                    .set(resultsContainer, { opacity: 1 })
                    .to(goodFeels, waveDuration, { yPercent: -100, ease: 'Quad.easeInOut' }, 0)
                    .fromTo(resultsContainer, waveDuration, { yPercent: 100 }, {
                        yPercent: 0,
                        ease: 'Quad.easeInOut'
                    }, 0.1);

            }, 100);
        },

        destroy() {
            if (this.quizResizeObserver) {
                this.quizResizeObserver.disconnect();
                this.quizResizeObserver = null;
            }
            if (this.quizFooterResizeObserver) {
                this.quizFooterResizeObserver.disconnect();
                this.quizFooterResizeObserver = null;
            }
            Viewport.off('resize', this.onQuizResize);
            if (this.wave) {
                this.wave.destroy();
                this.wave = null;
            }
        },

        /**
         *
         */
        onQuizResize() {
            this.scrollToBottom();
        },
        /**
         *
         */
        onFooterResize() {
            const { quizFooter } = this.$refs;
            const { height } = quizFooter.getBoundingClientRect();
            $(this.$el).css({
                paddingBottom: `${Math.round(height)}px`
            });
            this.scrollToBottom();
        },
        /**
         * Submitting the form...
         * @param e
         */
        onSubmit(e) {
            e.preventDefault();
            this.submit();
        },
        /**
         * Event handler for question inputs
         * @param e
         */
        onInputChange(e) {
            const { checked } = e.target;
            if (checked) return;
            // An answer was removed/nulled. Remove that answer, and any answers for any succeeding questions
            let answers = {};
            const question = $(e.target).parent('[data-question]').data('question');
            const index = this.questions.indexOf(question);
            this.questions.slice(0, index).forEach(question => {
                answers = { ...answers, [question]: this.answers[question] };
            });
            this.answers = answers;
        },
        /**
         * Transition callback before a question appears
         * @param el
         */
        onBeforeQuestionEnter(el) {
            gsap.set(el, { opacity: 0, height: 0 })
        },
        /**
         * Transition callback when a question appears
         * @param el
         * @param done
         */
        onQuestionEnter(el, done) {
            Components.init(el);
            gsap.timeline({
                onComplete: done
            })
                .fromTo(el, 0.3, { opacity: 0 }, { opacity: 1 }, 0)
                .fromTo(el, 0.5, { height: 0 }, { height: 'auto', ease: 'Cubic.easeInOut' }, 0)
                .fromTo(el.firstChild, 0.5, { yPercent: 33 }, {
                    yPercent: 0,
                    ease: 'Cubic.easeOut'
                }, 0.2);
        },
        /**
         *
         * @param el
         * @param done
         */
        onQuestionLeave(el, done) {
            gsap.timeline({
                onComplete: done
            })
                .to(el, 0.3, { opacity: 0 }, 0)
                .to(el, 0.5, { height: 0, ease: 'Cubic.easeInOut' }, 0);
        },
        /**
         *
         * @param el
         * @param done
         */
        onQuestionResponseEnter(el, done) {
            gsap
                .fromTo(el, {
                    duration: 0.3,
                    onComplete: done,
                    height: 0,
                    opacity: 0
                }, { height: 'auto', opacity: 1 });
        },
        /**
         *
         * @param el
         * @param done
         */
        onQuestionResponseLeave(el, done) {
            gsap
                .to(el, { duration: 0.3, onComplete: done, height: 0, opacity: 0 });
        }
    },
    created() {
        this.questions = $(el).find('[data-question]').get().map(question => question.dataset.question);
    },
    mounted() {
        const { quizFooter, waveSizer } = this.$refs;
        this.quizResizeObserver = new ResizeObserver(this.onQuizResize);
        this.quizResizeObserver.observe(this.$el);
        this.quizFooterResizeObserver = new ResizeObserver(this.onFooterResize);
        this.quizFooterResizeObserver.observe(quizFooter);
        Viewport.on('resize', this.onQuizResize);
        const { wave } = this.$refs;
        this.wave = new WaveWipe(wave, {
            direction: 'up',
            speed: 0.65,
            sizeElement: waveSizer
        });
    },
    destroyed() {
        this.destroy();
    }
});
