import $ from '@vaersaagod/tools/Dom';
import Config from '@vaersaagod/tools/Config';
import Dispatch from '@vaersaagod/tools/Dispatch';
import WaveWipe from '../lib/WaveWipe';
import gsap from 'gsap';

import * as eventKeys from '../lib/events';
import Viewport from "@vaersaagod/tools/Viewport";

export default (el, props) => {

    const $el = $(el);
    const $top = $el.find('[data-cart-top]');
    const $bg = $el.find('[data-cart-bg]');
    const $logoWrapper = $el.find('[data-cart-logo]');
    const $closeWrapper = $el.find('[data-cart-close]');
    const $contents = $el.find('[data-cart-contents]');
    const $contentsInner = $el.find('[data-cart-contents-inner]');
    const $closeLink = $el.find('[data-close-cart-link]');
    const canvas = $contents.find('canvas').get(0);
    const color = $contents.css('backgroundColor');

    const id = el.id;
    let isOpen = false;
    let wave;

    let focusedElementBeforeOpen;

    const closeCart = () => {

        if (!isOpen) {
            return;
        }

        isOpen = false;

        Viewport.releaseTabbing(focusedElementBeforeOpen);

        wave.reverse();

        gsap.timeline()
            .to($contentsInner.get(0), { duration: 0.4, xPercent: 100, ease: 'sine.in' }, 0)
            .to($closeWrapper.get(0), { duration: 0.3, xPercent: 100, ease: 'sine.in' }, 0)
            .to($bg.get(0), { duration: 0.3, opacity: 0 }, 0.1)
            .to($logoWrapper.get(0), { duration: 0.3, opacity: 0 }, 0.1);
    };

    const onCartOpen = (tween = true) => {

        if (isOpen) {
            return;
        }

        isOpen = true;
        el.hidden = false;

        focusedElementBeforeOpen = document.activeElement || null;

        Viewport.releaseTabbing();
        Viewport.lockTabbing([el], $closeLink.get(0));

        wave.wipe(tween);

        const timeline = gsap
            .timeline()
            .fromTo($bg.get(0), { opacity: 0 }, { duration: 0.3, opacity: 0.75 }, 0)
            .fromTo($logoWrapper.get(0), { opacity: 0 }, { duration: 0.2, opacity: 1 }, 0)
            .fromTo($contentsInner.get(0), { xPercent: 100 }, { duration: 0.6, xPercent: 0, ease: 'quint.out' }, 0.3)
            .fromTo($closeWrapper.get(0), { xPercent: 100 }, { duration: 0.7, xPercent: 0, ease: 'quint.out' }, 0.3);

        if (!tween) {
            timeline.progress(1);
        }
    };

    const keyup = e => {
        const key = e.keyCode || e.which;
        if (isOpen && key === 27) {
            closeCart();
        }
    };

    const init = () => {
        $closeLink.on('click', e => {
            e.preventDefault();
            closeCart();
        });

        $bg.on('click', e => {
            closeCart();
        });

        // Create the bg wipe effect
        canvas.hidden = false;
        $contents.css({ backgroundColor: 'transparent' });

        wave = new WaveWipe(canvas, {
            color,
            container: $contents.get(0),
            direction: 'right',
            speed: 0.5,
            onComplete: () => {},
            onReverseComplete: () => {
                el.hidden = true;
            }
        });

        document.addEventListener('keyup', keyup);
        Dispatch.on(eventKeys.CART_OPEN, onCartOpen);

        // Account for the menu being opened already before the JS had the chance to boot
        if (id && window.location.hash === `#${id}`) {
            onCartOpen(false);
            if (window.history && window.history.replaceState) {
                history.replaceState(null, document.title, `${window.location.pathname}${window.location.search}`);
            }
        }

    };

    const destroy = () => {
        $closeLink.off('click');
        $bg.off('click');
        document.removeEventListener('keyup', keyup);
        Dispatch.off(eventKeys.CART_OPEN, onCartOpen);
    };

    return {
        init,
        destroy
    };
};
