import Dispatch from "@vaersaagod/tools/Dispatch";
import Vue from 'vue';

import { getAxios, t } from '../../lib/helpers';

export default (el, props) => new Vue({
    el,
    delimiters: ['${', '}'], // The only reason to use custom delimiters, is if you want to mix Vue and Twig templates,
    name: 'Membership',
    data: {
        plans: props.plans || [],
        planId: props.planId,
        frequency: props.frequency,
        quantity: props.quantity,
        shippingAddressId: props.shippingAddressId || null,
        billingAddressId: props.billingAddressId || null,
        shippingPrice: props.shippingPrice || 0,
        totalPrice: props.totalPrice || 0,
        planPreviewHtml: null
    },
    computed: {
        selectedFrequency() {
            switch (this.frequency) {
                case '2 WEEK':
                    return t('bi-weekly');
                case '4 WEEK':
                    return t('per month');
                default:
                    return t('per week');
            }
        },
        selectedPlan() {
            if (!this.planId || !this.plans) {
                return null;
            }
            return this.plans.find(plan => plan.id === this.planId);
        },
        shippingPriceFormatted() {
            return this.$options.filters.formatCurrency(this.shippingPrice);
        },
        totalPriceFormatted() {
            return this.$options.filters.formatCurrency(this.totalPrice);
        },
        priceData() {
            return {
                planId: this.planId,
                quantity: this.quantity,
                shippingAddressId: this.shippingAddressId
            };
        }
    },
    watch: {
        planId() {
            this.updatePlanPreviewHtml();
        },
        priceData: {
            deep: true,
            handler() {
                this.updateShippingPriceAndTotal();
            }
        }
    },
    methods: {
        updateShippingPriceAndTotal() {
            getAxios()
                .post(null, {
                    action: 'talormade/memberships/default/get-membership-shipping-and-total',
                    ...this.priceData
                })
                .then(({ status, data }) => {
                    console.log({ status, data });
                    if (status !== 200 || !data) {
                        throw new Error();
                    }
                    const { shipping: shippingPrice, total: totalPrice } = data;
                    this.shippingPrice = shippingPrice;
                    this.totalPrice = totalPrice;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        updatePlanPreviewHtml() {
            if (this.planId) {
                getAxios()
                    .post(null, {
                        action: 'talormade/memberships/default/get-plan-preview-html',
                        planId: this.planId
                    })
                    .then(({ data }) => {
                        const { html = null } = data || {};
                        if (html) {
                            this.planPreviewHtml = html;
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
            } else {
                this.planPreviewHtml = null;
            }
        },
        /**
         *
         * @param key
         * @param planId
         */
        onPlanChange(key, planId) {
            this.planId = planId;
        },
        /**
         *
         * @param key
         * @param frequency
         */
        onFrequencyChange(key, frequency) {
            this.frequency = frequency;
        },
        /**
         *
         * @param key
         * @param quantity
         */
        onQuantityChange(key, quantity) {
            this.quantity = parseInt(quantity, 10) || 1;
        },
        /**
         *
         * @param key
         * @param shippingAddressId
         */
        onShippingAddressChange(key, shippingAddressId) {
            this.shippingAddressId = shippingAddressId;
        },
        /**
         *
         * @param key
         * @param billingAddressId
         */
        onBillingAddressChange(key, billingAddressId) {
            this.billingAddressId = billingAddressId;
        }
    },
    mounted() {
        Dispatch.on('listbox:plan:change', this.onPlanChange);
        Dispatch.on('listbox:frequency:change', this.onFrequencyChange);
        Dispatch.on('listbox:quantity:change', this.onQuantityChange);
        Dispatch.on('listbox:shippingAddress:change', this.onShippingAddressChange);
        Dispatch.on('listbox:billingAddress:change', this.onBillingAddressChange);
    },
    destroyed() {
        Dispatch.off('listbox:plan:change', this.onPlanChange);
        Dispatch.off('listbox:frequency:change', this.onFrequencyChange);
        Dispatch.off('listbox:quantity:change', this.onQuantityChange);
        Dispatch.off('listbox:shippingAddress:change', this.onShippingAddressChange);
        Dispatch.off('listbox:billingAddress:change', this.onBillingAddressChange);
    }
});
