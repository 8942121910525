import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import request from '@vaersaagod/tools/request';
import serialize from 'form-serialize';

import * as eventKeys from '../../lib/events';

export default (el, props) => {
    const $el = $(el);
    const $sumbitBtn = $el.find('[data-submit-btn]');
    const { productId, purchasableId, grindType, isCoffee } = props || {};
    
    let isRequesting = false;
    
    const addedToCartSuccess = result => {
        Dispatch.emit(eventKeys.CART_ADDED_PRODUCT, {
            productId: productId,
            purchasableId: purchasableId,
            grindType: grindType,
            isCoffee: isCoffee,
            cart: result.cart
        });
        
        Dispatch.emit(eventKeys.CART_UPDATED, {
            cart: result.cart
        });
    };
    
    const showErrorMessages = response => {
        let errors = [];
        
        if (response.error !== undefined && response.error !== '') {
            errors.push(response.error);
        }

        if (response.errors !== undefined) {
            for (let key of Object.keys(response.errors)) {
                let err = response.errors[key];
                errors = [...errors, ...err];
            }
        }
        
        Dispatch.emit(eventKeys.ADD_ERROR_ALERT, {
            message: errors.join('<br>'),
            duration: 5
        });
    };
    
    const showGenericError = errorMessage => {
        Dispatch.emit(eventKeys.ADD_ERROR_ALERT, {
            message: errorMessage ? errorMessage : 'An error occured when trying to add product to cart!',
            duration: 5
        });
    };
    
    const submitForm = () => {
        if (!isRequesting) {
            const fields = serialize($el.get(0), { hash: false });
            isRequesting = true;

            $el.addClass('js-is-submitting');
            
            request
                .post(document.location.href)
                .accept('application/json')
                .send(fields)
                .then(res => {
                    const { status, body } = res || {};
                    if (status === 200 && body) {
                        if (body.success === true) {
                            addedToCartSuccess(body);
                        } else {
                            //console.error('her', body);
                            showErrorMessages(body);
                        }
                    } else {
                        //console.error('der');
                        showGenericError(null);
                    }
                })
                .catch(error => {
                    //console.error('ger');
                    showGenericError(null);
                })
                .then(() => {
                    isRequesting = false;
                    $el.removeClass('js-is-submitting');
                });
        }
    };
    
    const init = () => {

        $el.on('submit', e => {
            e.preventDefault();
            submitForm();
        });
        
    };

    const destroy = () => {
        $el.off('submit');
    };

    return {
        init,
        destroy
    };
};
