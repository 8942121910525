import Vue from 'vue';
import Dispatch from "@vaersaagod/tools/Dispatch";
import { getAxios, t } from "../../lib/helpers";

export default (el, props) => new Vue({
    el,
    delimiters: ['${', '}'], // The only reason to use custom delimiters, is if you want to mix Vue and Twig templates,
    name: 'EditMembership',
    data: {
        plans: props.plans || [],
        planId: props.planId,
        frequency: props.frequency,
        quantity: props.quantity,
        grindType: props.grindType,
        shippingAddressId: props.shippingAddressId,
        billingAddressId: props.billingAddressId,
        shippingPrice: props.shippingPrice || 0,
        totalPrice: props.totalPrice || 0
    },
    computed: {
        selectedFrequency() {
            switch (this.frequency) {
                case '2 WEEK':
                    return t('bi-weekly');
                case '4 WEEK':
                    return t('per month');
                default:
                    return t('per week');
            }
        },
        selectedPlan() {
            if (!this.planId || !this.plans) {
                return null;
            }
            return this.plans.find(plan => plan.id === this.planId);
        },
        enableSubmit() {
            return this.planId !== props.planId || this.quantity !== props.quantity || this.frequency !== props.frequency || this.grindType !== props.grindType || this.shippingAddressId !== props.shippingAddressId || this.billingAddressId !== props.billingAddressId;
        },
        shippingPriceFormatted() {
            return this.$options.filters.formatCurrency(this.shippingPrice);
        },
        totalPriceFormatted() {
            return this.$options.filters.formatCurrency(this.totalPrice);
        },
        priceData() {
            return {
                planId: this.planId,
                quantity: this.quantity,
                shippingAddressId: this.shippingAddressId
            };
        }
    },
    watch: {
        priceData: {
            deep: true,
            handler() {
                this.updateShippingPriceAndTotal();
            }
        }
    },
    methods: {
        updateShippingPriceAndTotal() {
            getAxios()
                .post(null, {
                    action: 'talormade/memberships/default/get-membership-shipping-and-total',
                    ...this.priceData
                })
                .then(({ status, data }) => {
                    if (status !== 200 || !data) {
                        throw new Error();
                    }
                    const { shipping: shippingPrice, total: totalPrice } = data;
                    this.shippingPrice = shippingPrice;
                    this.totalPrice = totalPrice;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        /**
         *
         * @param key
         * @param planId
         */
        onPlanChange(key, planId) {
            this.planId = planId;
        },
        /**
         *
         * @param key
         * @param frequency
         */
        onFrequencyChange(key, frequency) {
            this.frequency = frequency;
        },
        /**
         *
         * @param key
         * @param quantity
         */
        onQuantityChange(key, quantity) {
            this.quantity = parseInt(quantity, 10) || 1;
        },
        /**
         *
         * @param key
         * @param grindType
         */
        onGrindTypeChange(key, grindType) {
           this.grindType = grindType;
        },
        /**
         *
         * @param key
         * @param shippingAddressId
         */
        onShippingAddressChange(key, shippingAddressId) {
            this.shippingAddressId = shippingAddressId;
        },
        /**
         *
         * @param key
         * @param billingAddressId
         */
        onBillingAddressChange(key, billingAddressId) {
            this.billingAddressId = billingAddressId;
        }
    },
    mounted() {
        Dispatch.on('listbox:plan:change', this.onPlanChange);
        Dispatch.on('listbox:frequency:change', this.onFrequencyChange);
        Dispatch.on('listbox:quantity:change', this.onQuantityChange);
        Dispatch.on('listbox:grindType:change', this.onGrindTypeChange);
        Dispatch.on('listbox:shippingAddress:change', this.onShippingAddressChange);
        Dispatch.on('listbox:billingAddress:change', this.onBillingAddressChange);
    },
    destroyed() {
        Dispatch.off('listbox:plan:change', this.onPlanChange);
        Dispatch.off('listbox:frequency:change', this.onFrequencyChange);
        Dispatch.off('listbox:quantity:change', this.onQuantityChange);
        Dispatch.off('listbox:grindType:change', this.onGrindTypeChange);
        Dispatch.off('listbox:shippingAddress:change', this.onShippingAddressChange);
        Dispatch.off('listbox:billingAddress:change', this.onBillingAddressChange);
    }
});
