<template>
    <div class="mini-select">
        <select ref="select" :value="grindType"
                :name="'lineItems[' + itemId + '][options][grindType]'"
                v-on:change="onChangedGrindType">
            <option v-for="(val, key) in grindTypes" :value="key">{{ val }}</option>
        </select>
    </div>
</template>

<script>
    import $ from '@vaersaagod/tools/Dom';

    export default {
        name: "MiniSelect.vue",
        props: {
            itemId: Number,
            grindType: String,
            grindTypes: Object,
            setGrindType: Function,
        },
        watch: {
            grindType() {
                this.resizeSelect(this.grindType);
            }
        },
        components: {},
        computed: {},
        data: function () {
            return {
                isEdge: false
            }
        },
        methods: {
            resizeSelect(byValue) {
                const $select = $(this.$refs.select);
                const $dummyWrap = $('<div/>');
                const $dummy = $('<span/>');
                $dummyWrap.append($dummy);
                $dummy.css({ display: 'inline-block', fontSize: $select.css('font-size') });
                $('body').append($dummyWrap);
                $dummy.text(this.grindTypes[byValue]);
                $select.css({ width: Math.max($dummy.width() + (this.isEdge ? 6 : 0), 40) });
                $dummyWrap.remove();
            },
            onChangedGrindType(e) {
                this.setGrindType(this.itemId, this.$refs.select.value);
                this.resizeSelect(this.$refs.select.value);
            },
        },
        mounted() {
            this.isEdge = $('html').hasClass('is-msedge');
            this.resizeSelect(this.grindType);
        }
    }
</script>
