import $ from '@vaersaagod/tools/Dom';
import Dispatch from "@vaersaagod/tools/Dispatch";
import Vue from 'vue';

import gsap from 'gsap';

export default (el, props) => new Vue({
    el,
    delimiters: ['${', '}'], // The only reason to use custom delimiters, is if you want to mix Vue and Twig templates
    data() {
        return {
            expanded: false,
            name: props.name,
            selectedOption: props.selectedOption || null
        };
    },
    watch: {
        value(newValue) {
            Dispatch.emit(`listbox:${this.name}:change`, newValue);
        },
        expanded(isExpanded) {
            if (isExpanded) {
                Vue.nextTick(() => this.$refs.listbox.focus());
            } else {
                try {
                    this.$refs.toggle.focus();
                } catch (error) {
                    console.warn(error);
                }
            }
        }
    },
    methods: {
        toggle() {
            this.expanded = !this.expanded;
        },
        selectNextOption() {
            let index = this.selectedOptionIndex + 1;
            if (index > this.numOptions - 1) {
                index = 0;
            }
            const option = $(this.$el).find('[role="option"]').get(index);
            this.setSelectedOption(option);
        },
        selectPrevOption() {
            let index = this.selectedOptionIndex - 1;
            if (index < 0) {
                index = this.numOptions - 1;
            }
            const option = $(this.$el).find('[role="option"]').get(index);
            this.setSelectedOption(option);
        },
        setSelectedOption(node) {
            if (!node) {
                this.selectedOption = null;
                return;
            }
            this.selectedOption = {
                id: node.id,
                label: node.dataset.label,
                value: node.dataset.value
            };
        },
        // Event handlers
        onToggleClick() {
            this.toggle();
        },
        onBodyClickFocus(e) {
            // Make sure the listbox closes when something else is clicked or focused
            if (!this.expanded) {
                return;
            }
            const { target } = e;
            if (target === this.$el || this.$el.contains(target)) {
                return;
            }
            this.expanded = false;
        },
        onOptionClick(e) {
            const { currentTarget: option } = e;
            this.setSelectedOption(option);
            this.expanded = false;
        },
        onToggleKeyDown(e) {
            const key = e.which || e.keyCode;
            if (key !== 13) {
                return true;
            }
            e.preventDefault();
            e.stopPropagation();
            return false;
        },
        onToggleKeyUp(e) {
            const key = e.which || e.keyCode;
            if (key === 40) {
                this.expanded = true;
                this.selectNextOption();
                return false;
            } else if (key === 38) {
                this.expanded = true;
                this.selectPrevOption();
                return false;
            } else if (key === 13) {
                this.toggle();
                return false;
            }
            return true;
        },
        onListboxKeyUp(e) {
            const key = e.which || e.keyCode;
            switch (key) {
                case 38:
                    this.selectPrevOption();
                    break;
                case 40:
                    this.selectNextOption();
                    break;
                case 27: case 13:
                    this.expanded = false;
                    break;
            }
        },
        /**
         *  Transitions
         */
        onBeforeOptionsEnter(node) {
            gsap.set(node, { height: 0, y: -10, opacity: 1 });
        },
        onOptionsEnter(node, onComplete) {
            gsap.timeline({ onComplete })
                .to(node, 0.5, { height: 'auto', y: 0, ease: 'Quint.easeOut' }, 0);
        },
        onOptionsLeave(node, onComplete) {
            gsap.killTweensOf(node);
            onComplete();
        },
        onOverlayEnter(node, onComplete) {
            gsap.timeline({ onComplete })
                .fromTo(node, 0.3, { opacity: 0 }, { opacity: 0.3 }, 0);
        },
        onOverlayLeave(node, onComplete) {
            gsap.timeline({ onComplete })
                .to(node, 0.15, { opacity: 0 }, { opacity: 0 }, 0);
        }
    },
    computed: {
        value() {
            return this.selectedOption ? this.selectedOption.value : null;
        },
        numOptions() {
            return this.$el.querySelectorAll('[role="option"]').length;
        },
        selectedOptionIndex() {
            const selectedOption = this.$el.querySelector(`[role="option"][data-value="${this.value}"]`);
            if (!selectedOption) {
                return -1;
            }
            return Array.from(selectedOption.parentNode.children).indexOf(selectedOption);
        }
    },
    mounted() {
        $('body').on('click focusin', this.onBodyClickFocus);
    },
    destroyed() {
        $('body').off('click focusin', this.onBodyClickFocus);
    }
});
