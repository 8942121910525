import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';

import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const $letters = $el.find('svg path');

    let masterTimeline = null;
    let variant = props.variant || 0;
    let isSmaller = props.smaller || false;
    let scaleFactor = isSmaller ? 0.7 : 1;

    const createInTimeline = () => {
        if (masterTimeline) {
            masterTimeline.kill();
        }

        masterTimeline = gsap.timeline({ repeat: 0 });

        $letters.each((letter, i) => {
            const isOdd = i % 2;
            const isFirst = i === 0;
            const isLast = i === $letters.length - 1;

            if (isFirst && variant === 1) {
                masterTimeline.to(letter, {
                    y: -20,
                    x: -10,
                    scale: 1.0,
                    rotation: -10,
                    transformOrigin: 'center center',
                    duration: 1.5,
                    ease: 'expo.out'
                }, 0);
            } else if (isLast && variant === 1) {
                masterTimeline.to(letter, {
                    x: 5,
                    y: Math.round(((Math.random() * 30) - 15) * scaleFactor),
                    rotation: Math.round((Math.random() * 8) * scaleFactor) * (isOdd ? -1 : 1),
                    scale: 1.00,
                    transformOrigin: 'center center',
                    duration: 1.5,
                    ease: 'elastic.out(0.8, 0.3)'
                }, 0);
            } else if (isLast && variant === 2) {
                masterTimeline.to(letter, {
                    y: -30,
                    x: 10,
                    scale: 1.0,
                    rotation: 20,
                    transformOrigin: '70% 70%',
                    duration: 1.52,
                    ease: 'expo.out'
                }, 0);
            } else if (variant === 3 && i === 2) {
                const specialTimeline = gsap.timeline({ repeat: -1, yoyo: true });

                specialTimeline.to(letter, {
                    y: -40,
                    scaleY: 1.1,
                    scaleX: 0.95,
                    rotation: 0,
                    transformOrigin: 'center center',
                    duration: 0.35,
                    ease: 'sine.out'
                }, 0);

                masterTimeline.add(specialTimeline, 0);
            } else if (variant === 3 && isLast) {
                masterTimeline.to(letter, {
                    x: 5,
                    y: Math.round(((Math.random() * 30) - 15) * scaleFactor),
                    rotation: Math.round((Math.random() * 8) * scaleFactor) * (isOdd ? -1 : 1),
                    scale: 1.00,
                    transformOrigin: 'center center',
                    duration: 1.5,
                    ease: 'elastic.out(0.8, 0.3)'
                }, 0);
            } else {
                masterTimeline.to(letter, {
                    y: Math.round(((Math.random() * 30) - 15) * scaleFactor),
                    rotation: Math.round((Math.random() * 8) * scaleFactor) * (isOdd ? -1 : 1),
                    scale: 1.00,
                    transformOrigin: 'center center',
                    duration: 1.5,
                    ease: 'elastic.out(0.8, 0.3)'
                }, 0);
            }
        });

        masterTimeline.pause();
    };

    const createOutTimeline = () => {
        if (masterTimeline) {
            masterTimeline.kill();
        }

        masterTimeline = gsap.timeline({ repeat: 0 });

        $letters.each((letter, i) => {
            const isOdd = i % 2;
            const isFirst = i === 0;
            const isLast = i === $letters.length - 1;

            if (isFirst) {

            }

            masterTimeline.to(letter, {
                y: 0,
                x: 0,
                rotation: 0,
                scale: 1,
                //transformOrigin: 'center center',
                duration: 0.3,
                ease: 'Sine.easeOut'
            }, 0);
        });

        masterTimeline.pause();
    };

    const onResize = e => {

    };

    const onMouseEnter = e => {
        createInTimeline();
        masterTimeline.play();
    };

    const onMouseLeave = e => {
        createOutTimeline(true);
        masterTimeline.play();
    };


    const init = () => {
        $el.find('svg').css({ overflow: 'visible' });

        $el.on('mouseenter', onMouseEnter);
        $el.on('mouseleave', onMouseLeave);
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        $el.off('mouseenter', onMouseEnter);
        $el.off('mouseleave', onMouseLeave);
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };
};
