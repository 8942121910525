import Viewport from '@vaersaagod/tools/Viewport';
import Vue from 'vue';
import VueConfetti from 'vue-confetti';

Vue.use(VueConfetti);

export default (el, props) => new Vue({
    el,
    data() {
        return {
            width: Viewport.width,
            height: Viewport.height
        };
    },
    methods: {
        onResize() {
            this.width = Viewport.width;
            this.height = Viewport.height;
        }
    },
    mounted() {
        const { id: canvasId } = this.$refs.canvas;
        this.$confetti.start({
            canvasId,
            particles: [{
                type: 'rect',
                colors: ['#FFDEED', '#F4B3CE', '#D61D64', '#FFC98B', '#F38886', '#19A786', '#28A6D3']
            }]
        });
        Viewport.on('resize', this.onResize);
        if (props.duration) {
            setTimeout(() => {
                this.$confetti.stop();
            }, props.duration * 1000);
        }
    },
    destroyed() {
        this.$confetti.stop();
        Viewport.off('resize', this.onResize);
    }
});
