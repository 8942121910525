import Vue from 'vue';
import { validateInput } from "../lib/validate";
import set from "lodash/set";
import omit from "lodash/omit";
import $ from "@vaersaagod/tools/Dom";
import Dispatch from "@vaersaagod/tools/Dispatch";
import { ADD_ERROR_ALERT } from "../lib/events";
import { getAxios, t } from "../lib/helpers";
import serialize from "form-serialize";

const VALIDATION_ERROR_ALERT = t('ERROR_MESSAGE_VALIDATION');
const SERVER_ERROR_ALERT = t('ERROR_MESSAGE_SERVER');

const ERROR_ALERT_DURATION = 5;

export default (el, props) => new Vue({
    el,
    delimiters: ['${', '}'], // The only reason to use custom delimiters, is if you want to mix Vue and Twig templates,
    name: 'ContactForm',
    data() {
        return {
            name: props.name,
            email: props.email,
            phone: props.phone,
            message: props.message,
            isSubmitting: false,
            hasSubmitted: false,
            errors: null,
            errorMessage: null
        };
    },
    watch: {
        errors() {
            if (this.hasErrors) {
                // Focus to first input with an error
                Vue.nextTick(() => {
                    $('html').addClass('outline').removeClass('no-outline');
                    const firstErrorInput = this.$el.querySelector('[data-haserror]');
                    if (firstErrorInput) {
                        try {
                            firstErrorInput.focus();
                        } catch (error) {
                            // Don't care.
                        }
                    }
                });
            }
        },
        errorMessage(message) {
            if (!message) {
                return null;
            }
            Dispatch.emit(ADD_ERROR_ALERT, {
                message,
                duration: ERROR_ALERT_DURATION
            });
            this.errorMessage = null;
        }
    },
    computed: {
        /**
         *
         * @returns {boolean}
         */
        hasErrors() {
            return this.errors && Object.keys(this.errors).length;
        }
    },
    methods: {

        addError(key, error) {
            this.errors = set({
                ...(this.errors || {})
            }, key, error);
        },

        clearError(key) {
            if (!this.errors || !this.$_get(this.errors, key)) {
                return;
            }
            this.errors = omit(this.errors, key);
        },

        /**
         * Validate the form
         *
         * @returns {boolean}
         */
        validateForm() {
            let hasErrors = false;
            const inputs = this.$el.querySelectorAll('input[name]:not([type="hidden"]),select[name],textarea[name]');
            inputs.forEach(input => {
                const error = validateInput(input);
                if (!error) {
                    this.clearError(input.name);
                    return;
                }
                this.addError(input.name, error);
                hasErrors = true;
            });
            return !hasErrors;
        },

        submitForm() {

            if (this.isSubmitting || this.hasSubmitted) {
                return;
            }

            // Validate form
            const formIsValid = this.validateForm();

            // Eject if form contains errors
            if (!formIsValid) {
                this.errorMessage = VALIDATION_ERROR_ALERT;
                return;
            }

            // Submit the form
            this.isSubmitting = true;

            const client = getAxios();
            const data = serialize(this.$el, { hash: true });

            client
                .post(null, data)
                .then(({ status, data }) => {
                    if (status !== 200) {
                        throw new Error();
                    }
                    const { errors = null, message: errorMessage = null, redirect } = data;
                    if (errors) {
                        this.errors = errors;
                    }
                    if (errorMessage) {
                        this.errorMessage = errorMessage;
                    }
                    if (!errors && !errorMessage) {
                        this.hasSubmitted = true;
                        window.location.href = `/${redirect}`;
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.errorMessage = SERVER_ERROR_ALERT;
                })
                .then(() => {
                    this.isSubmitting = false;
                });

        },

        /**
         * --------- Event handlers --------
         */

        onFormSubmit(e) {
            e.preventDefault();
            this.submitForm();
        }
    }
});
