import $ from '@vaersaagod/tools/Dom';
import Dispatch from "@vaersaagod/tools/Dispatch";

import { NEW_SUBSCRIPTION_UPDATED } from "../../lib/events";
import { getAxios } from "../../lib/helpers";

export default el => {

    const $el = $(el);

    const onSubscriptionUpdate = () => {
        getAxios()
            .post(null, {
                action: 'talormade/memberships/subscribe/get-cart-html'
            })
            .then(({ status, data }) => {
                if (status !== 200 || !data.html) {
                    throw new Error();
                }
                const $html = $(data.html);
                $el.html($html.html());
            })
            .catch(error => {
                console.error(error);
            });
    };

    const init = () => {
        Dispatch.on(NEW_SUBSCRIPTION_UPDATED, onSubscriptionUpdate, true);
    };

    const destroy = () => {
        Dispatch.off(NEW_SUBSCRIPTION_UPDATED, onSubscriptionUpdate);
    };

    return {
        init,
        destroy
    };

};
