import Vue from 'vue';
import isEqual from 'lodash/isEqual';

export default (el, props) => new Vue({
    el,
    delimiters: ['${', '}'], // The only reason to use custom delimiters, is if you want to mix Vue and Twig templates,
    name: 'EditAccount',
    data() {
        return {
            user: props.user,
            errors: { ...props.errors }
        };
    },
    mounted() {
        const errorInput = this.$refs.form.querySelector('[data-haserror]');
        if (!errorInput) {
            return;
        }
        try {
            errorInput.focus();
        } catch (error) {};
    }
});
