import $ from '@vaersaagod/tools/Dom';
import Config from '@vaersaagod/tools/Config';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Viewport from "@vaersaagod/tools/Viewport";

import gsap from 'gsap';

import * as eventKeys from '../lib/events';

import WaveWipe from "../lib/WaveWipe";

const CONTENT_SELECTOR = '[data-mainmenu-content]';
const PRIMARYMENU_SELECTOR = '[data-mainmenu-menu]';
const LOGINFORM_SELECTOR = '[data-mainmenu-login-form]';
const LOGINTOGGLE_SELECTOR = '[data-mainmenu-logintoggle]';
const SECONDARYMENU_SELECTOR = '[data-mainmenu-secondary-menu]';
const CLOSEBTN_SELECTOR = '[data-mainmenu-closebtn]';

export default el => {

    const $el = $(el);
    const id = $el.attr('id');

    const closeBtn = $el.find(CLOSEBTN_SELECTOR).get(0);
    const loginToggle = $el.find(LOGINTOGGLE_SELECTOR).get(0);
    const content = $el.find(CONTENT_SELECTOR).get(0);
    const primaryMenuArea = $el.find(PRIMARYMENU_SELECTOR).get(0);
    const loginFormArea = $el.find(LOGINFORM_SELECTOR).get(0);
    const secondaryMenuArea = $el.find(SECONDARYMENU_SELECTOR).get(0);
    const logo = $el.find('[data-logo]').get(0);
    const menuItems = $el.find('[data-menuitem] > *').get();

    const $userFirstname = $el.find('[data-user-firstname]');

    let isOpen = false;
    let isLoginOpen = false;
    let focusedElementBeforeOpen = null;
    let isAnimating = false;

    let wave;

    const hideLogin = (tween = true) => {
        if (!isLoginOpen) return;
        isLoginOpen = false;
        primaryMenuArea.hidden = false;
        loginFormArea.hidden = true;
        $(loginFormArea).find('input:not([type="hidden"]),button[type="submit"],a').attr('tabIndex', '-1');
        $(loginToggle).find('[data-label]').each(span => span.hidden = span.dataset.label !== 'login');
        $el.removeClass('js-login-opened');
    };

    const showLogin = (tween = true) => {
        if (isLoginOpen) return;
        isLoginOpen = true;
        primaryMenuArea.hidden = true;
        loginFormArea.hidden = false;
        $(loginFormArea).find('input:not([type="hidden"]),button[type="submit"],a').attr('tabIndex', '0');
        $(loginToggle).find('[data-label]').each(span => span.hidden = span.dataset.label === 'login');
        $el.addClass('js-login-opened');
    };

    const toggleLogin = (tween = true) => {
        if (isLoginOpen) {
            hideLogin(tween);
        } else {
            showLogin(tween);
        }
    };

    /**
     * Hook that runs after the menu is fully closed
     */
    const afterClose = () => {
        isAnimating = false;
        el.hidden = true;
        hideLogin(true);
        Dispatch.emit(eventKeys.MENU_AFTER_CLOSE);
    };

    /**
     * Hook that runs after the menu is fully open
     */
    const afterOpen = () => {
        isAnimating = false;
        Dispatch.emit(eventKeys.MENU_AFTER_OPEN);
    };

    const closeMenu = (tween = true) => {

        if (!isOpen) {
            return;
        }

        Dispatch.emit(eventKeys.MENU_BEFORE_CLOSE);

        isOpen = false;

        Viewport.releaseTabbing(focusedElementBeforeOpen);

        const menuTl = gsap.timeline();

        menuTl
            .to(content, 0.5, {
                yPercent: -100,
                ease: 'Cubic.easeIn'
            }, 0);

        menuTl
            .to(menuItems, 0.3, {
                xPercent: 0,
                yPercent: 0,
                rotate: 0
            }, 0);

        menuTl
            .to([logo, closeBtn], 0.3, {
                opacity: 0
            }, 0.25);

        menuTl
            .to(secondaryMenuArea, 0.3, {
                yPercent: 100,
                ease: 'Linear.easeNone'
            }, 0);

        menuTl
            .add(() => {
                wave.reverse(tween);
            }, 0.05);

        if (!tween) {
            menuTl.progress(1);
        }

    };

    const openMenu = (tween = true) => {

        if (isOpen) return;

        Dispatch.emit(eventKeys.MENU_BEFORE_OPEN);

        isOpen = true;
        el.hidden = false;
        $el.css({ opacity: 1 });

        focusedElementBeforeOpen = document.activeElement || null;

        Viewport.releaseTabbing();
        Viewport.lockTabbing([el], closeBtn);

        wave.wipe(tween);

        const menuTl = gsap.timeline({ delay: 0.1 });

        menuTl
            .fromTo([logo, closeBtn], 0.3, {
                opacity: isAnimating ? '' : 0
            }, {
                opacity: 1
            }, 0.1);

        menuTl
            .fromTo(content, 0.3, {
                opacity: isAnimating ? '' : 0
            }, {
                opacity: 1
            }, 0.13)
            .fromTo(content, 0.75, {
                yPercent: isAnimating ? '' : -100
            }, {
                yPercent: 0,
                ease: 'Quint.easeOut'
            }, 0.13);


        menuItems.forEach((item, index) => {
            const position = 0.13 + (0.05 * index);
            const duration = 0.5;
            const ease = 'Back.easeOut';
            switch (index % 3) {
                case 0:
                    menuTl.fromTo(item, duration, {
                        ease,
                        yPercent: isAnimating ? '' : 100,
                        rotate: isAnimating ? '' : 5
                    }, {
                        yPercent: 0,
                        rotate: 0
                    }, position);
                    break;
                case 1:
                    menuTl.fromTo(item, duration, {
                        ease,
                        yPercent: isAnimating ? '' : 100,
                        rotate: isAnimating ? '' : -5
                    }, {
                        yPercent: 0,
                        rotate: 0,
                        scale: 1
                    }, position);
                    break;
                case 2:
                    menuTl.fromTo(item, duration, {
                        ease,
                        yPercent: isAnimating ? '' : 100,
                        rotate: isAnimating ? '' : 10
                    }, {
                        yPercent: 0,
                        rotate: 0,
                        scale: 1
                    }, position);
            }
        });

        menuTl.fromTo(secondaryMenuArea, 0.3, {
            opacity: 0
        }, {
            opacity: 1
        }, 0.5)
            .fromTo(secondaryMenuArea, 0.5, {
                yPercent: isAnimating ? '' : 100
            }, {
                yPercent: 0,
                ease: 'Quint.easeOut'
            }, 0.5);

        if (!tween) {
            menuTl.progress(1);
        }

    };

    const toggleMenu = () => {
        if (isOpen) {
            closeMenu();
        } else {
            openMenu();
        }
    };

    const onKeyUp = e => {
        const key = e.keyCode || e.which;
        if (isOpen && key === 27) {
            closeMenu();
        }
    };

    const onUserLoggedIn = (key, data) => {
        window.location.href = '/my-account';
    };

    const onToggleMenu = () => {
        toggleMenu();
    };

    const onToggleLoginClick = e => {
        e.preventDefault();
        toggleLogin();
    };

    const onCloseBtnClick = e => {
        e.preventDefault();
        toggleMenu();
    };

    const init = () => {

        if (Config.get('loggedInUsername') !== '') {
            $userFirstname.text(Config.get('loggedInUsername'));
        }

        $el
            .on('click', LOGINTOGGLE_SELECTOR, onToggleLoginClick)
            .on('click', CLOSEBTN_SELECTOR, onCloseBtnClick);

        // Create the bg wipe effect
        const $bg = $el.find('[data-bg]');
        const color = $bg.css('backgroundColor');
        const canvas = $bg.find('canvas').get(0);
        canvas.hidden = false;

        wave = new WaveWipe(canvas, {
            color,
            direction: 'down',
            adaptiveDuration: false,
            onReady() {
                $bg.css({ backgroundColor: 'transparent' });
            },
            onComplete: afterOpen,
            onReverseComplete: afterClose
        });

        document.addEventListener('keyup', onKeyUp);

        Dispatch.on(eventKeys.TOGGLE_MENU, onToggleMenu, true);
        Dispatch.on(eventKeys.USER_LOGGED_IN, onUserLoggedIn, true);

        $el.addClass('js-booted');

        // Account for the menu being opened already before the JS had the chance to boot
        if (id && window.location.hash === `#${id}`) {
            openMenu(false);
            if (window.history && window.history.replaceState) {
                history.replaceState(null, document.title, `${window.location.pathname}${window.location.search}`);
            }
        }

    };

    const destroy = () => {

        $el.off('click');

        document.removeEventListener('keyup', onKeyUp);

        Dispatch.off(eventKeys.TOGGLE_MENU, onToggleMenu);
        Dispatch.off(eventKeys.USER_LOGGED_IN, onUserLoggedIn);

        // Close the menu and destroy the animation
        closeMenu(false);

    };

    return {
        init,
        destroy
    };
};
