import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';

export default class VideoLoop {
    constructor(el) {
        this.$el = $(el);

        this.$image = this.$el.find('[data-image]');
        this.$video = this.$el.find('[data-video]');
        this.video = this.$video.get(0);

        this.videoIsPlaying = false;
        this.videoFallbackTimer = null;

        this.videoSource = this.$video.attr('data-video');

        this.onLoadStart = this.onLoadStart.bind(this);
        this.onTimeUpdate = this.onTimeUpdate.bind(this);
        this.onFallbackTimer = this.onFallbackTimer.bind(this);
        // this.onResize = this.onResize.bind(this);

        this.init();
    }

    init() {
        if (!this.video.canPlayType || !this.video.canPlayType('video/mp4')) {
            this.cantPlayVideo();
            return;
        }

        if (!this.videoSource) {
            return;
        }

        this.killVideo();
        this.video.src = this.videoSource;

        this.playAndCatch();

        // Viewport.on('resize', this.onResize);
    }

    destroy() {
        this.killVideo();
    }

    stopFallbackTimer() {
        //console.log('stopFallbackTimer');
        // if (!this.videoFallbackTimer) {
        //     return;
        // }
        clearTimeout(this.videoFallbackTimer);
        this.videoFallbackTimer = null;
    }

    startFallbackTimer(interval) {
        //console.log('startFallbackTimer');
        this.stopFallbackTimer();
        this.videoFallbackTimer = setTimeout(this.onFallbackTimer, interval);
    }

    addVideoEventListeners() {
        this.video.addEventListener('timeupdate', this.onTimeUpdate);
        this.video.addEventListener('loadstart', this.onLoadStart);
        this.video.addEventListener('loadedmetadata', this.onLoadStart);
        this.video.addEventListener('loadeddata', this.onLoadStart);
        this.video.addEventListener('canplay', this.onLoadStart);
    }

    removeVideoEventListeners() {
        this.video.removeEventListener('timeupdate', this.onTimeUpdate);
        this.video.removeEventListener('loadstart', this.onLoadStart);
        this.video.removeEventListener('loadedmetadata', this.onLoadStart);
        this.video.removeEventListener('loadeddata', this.onLoadStart);
        this.video.removeEventListener('canplay', this.onLoadStart);
    }

    killVideo() {
        this.stopFallbackTimer();
        this.removeVideoEventListeners();
        this.video.pause();
        this.videoIsPlaying = false;
    }
    
    playAndCatch() {
        this.addVideoEventListeners();
        this.video.muted = true;
        this.startFallbackTimer(1000);

        const promise = this.video.play();

        if (promise !== undefined) {
            promise.then(() => {
                //console.log('playAndCatch.play');
                this.stopFallbackTimer();
            }).catch(e => {
                //console.log(['playAndCatch.catch', e]);
                
                if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                    //console.log('playAndCatch.catch: killing it');
                    this.cantPlayVideo();
                }
            });
        }
    }

    cantPlayVideo() {
        //console.log('cantPlayVideo');
        this.killVideo();
        this.$image.addClass('lazyload');
        if (this.$video.length) {
            this.$video.remove();
        }
    }

    onFallbackTimer() {
        //console.log('onFallbackTimer');
        this.cantPlayVideo();
    }

    onTimeUpdate() {
        if (!this.video || this.video.currentTime < 0.001) {
            return;
        }
        this.removeVideoEventListeners();
        this.stopFallbackTimer();
        this.$video.addClass('is-playing');
        this.videoIsPlaying = true;
    }

    onLoadStart() {
        if (this.videoIsPlaying) {
            return;
        }
        this.startFallbackTimer(6000);
    }
}
