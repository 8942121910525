import $ from '@vaersaagod/tools/Dom';
import Dispatch from "@vaersaagod/tools/Dispatch";
import Vue from 'vue';

import gsap from 'gsap';
import get from 'lodash/get';
import set from 'lodash/set';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import StateInput from "../../vue/StateInput";

import { getAxios, t } from "../../lib/helpers";

import { initCardElement, createPaymentMethod } from '../../lib/stripeCardElementFacade';

import { validateInput } from "../../lib/validate";

import { ADD_ERROR_ALERT, CART_UPDATED } from "../../lib/events";

const VALIDATION_ERROR_ALERT = t('ERROR_MESSAGE_VALIDATION');
const SERVER_ERROR_ALERT = t('ERROR_MESSAGE_SERVER');

const ERROR_ALERT_DURATION = 5;

export default (el, props) => new Vue({
    el,
    delimiters: ['${', '}'], // The only reason to use custom delimiters, is if you want to mix Vue and Twig templates,
    name: 'Checkout',
    components: {
        StateInput
    },
    data() {
        return {
            cart: props.cart,
            fields: {
                firstName: props.fields.firstName,
                lastName: props.fields.lastName,
                email: props.fields.email,
                phone: props.fields.phone,
                subscribeToNewsletter: false
            },
            shippingAddressId: props.shippingAddressId,
            shippingAddress: props.shippingAddress,
            billingAddressId: props.billingAddressId,
            billingAddress: props.billingAddress,
            billingAddressSameAsShipping: props.billingAddressSameAsShipping,
            estimatedShippingAddress: props.estimatedShippingAddress,
            estimatedBillingAddress: props.estimatedBillingAddress,
            acceptTerms: props.acceptTerms,
            registerNewUserOnOrderComplete: props.registerNewUserOnOrderComplete,
            errors: null,
            errorMessage: null,
            paymentMethodId: props.paymentMethodId,
            paymentSourceId: props.paymentSourceId,
            nameOnCard: props.nameOnCard,
            gatewayId: props.gatewayId,
            hasEnteredCardDetails: false,
            summaryIsReloading: false,
            isSubmitting: false,
            hasSubmitted: false
        };
    },
    computed: {
        hasErrors() {
            return this.errors && Object.keys(this.errors).length;
        },
        shippingAddressComputed() {
            const { useCustomerName } = this.shippingAddress;
            return {
                ...this.shippingAddress,
                firstName: useCustomerName ? this.fields.firstName : this.shippingAddress.firstName,
                lastName: useCustomerName ? this.fields.lastName : this.shippingAddress.lastName,
                phone: this.fields.phone
            };
        },
        billingAddressComputed() {
            const { useCustomerName } = this.billingAddress;
            return {
                ...this.billingAddress,
                firstName: useCustomerName ? this.fields.firstName : this.billingAddress.firstName,
                lastName: useCustomerName ? this.fields.lastName : this.billingAddress.lastName,
                phone: this.fields.phone
            };
        },
        shippingAddressCountryId() {
            return this.shippingAddress.countryId;
        },
        billingAddressCountryId() {
            return this.billingAddress.countryId;
        },
        formEnabled() {
            return !this.summaryIsReloading;
        },
        /**
         *
         * @returns {{address: {country: (*|null), city: (null|string), state: null, postal_code: (watch.shippingAddress.shippingAddress.zipCode|watch.billingAddress.billingAddress.zipCode|null|shippingAddress.shippingAddress.zipCode|billingAddress.billingAddress.zipCode), line2: null, line1: null}, name: string, email: Document.email}}
         */
        billingDetails() {
            let name;
            const addressToUse = this.billingAddressSameAsShipping ? this.shippingAddress : this.billingAddress;
            if (this.nameOnCard) {
                // Use name on card field
                name = this.nameOnCard;
            } else if (addressToUse.useCustomerName) {
                // Use the customer's name
                name = [this.fields.firstName, this.fields.lastName];
            } else {
                // Use the address' name
                name = [addressToUse.firstName, addressToUse.lastName];
            }
            const { stateId, stateName } = addressToUse;
            let state = null;
            if (stateId) {
                state = find(props.states, { id: stateId.toString() }, null);
                state = state && state.name ? state.name : null;
            } else if (stateName) {
                state = stateName;
            }
            return {
                name: [].concat(name).filter(i => !!i).join(' '),
                email: this.fields.email,
                address: {
                    city: addressToUse.city,
                    country: (find(props.countryIsos, { id: addressToUse.countryId }) || {}).iso || null,
                    line1: addressToUse.address1,
                    line2: addressToUse.address2,
                    postal_code: addressToUse.zipCode,
                    state
                }
            };
        }
    },
    watch: {
        errors() {
            if (this.hasErrors) {
                // Focus to first input with an error
                Vue.nextTick(() => {
                    $('html').addClass('outline').removeClass('no-outline');
                    const firstErrorInput = this.$refs.form.querySelector('[data-haserror]');
                    if (!firstErrorInput && this.card && !!this.errors.paymentMethodId) {
                        this.card.focus();
                    } else if (firstErrorInput) {
                        try {
                            firstErrorInput.focus();
                        } catch (error) {
                            // Don't care.
                        }
                    }
                });
            }
        },
        errorMessage(message) {
            if (!message) {
                return null;
            }
            Dispatch.emit(ADD_ERROR_ALERT, { message, duration: ERROR_ALERT_DURATION });
            this.errorMessage = null;
        },
        /**
         * Backend cart changed
         * Set shipping and billing addresses' IDs from the cart, then update the order summary
         */
        cart: {
            deep: true,
            handler(cart) {
                const { shippingAddressId = null, billingAddressId = null, paymentSourceId = null } = cart;
                if (paymentSourceId) {
                    this.paymentSourceId = paymentSourceId;
                }
                if (shippingAddressId) {
                    this.shippingAddressId = shippingAddressId;
                }
                if (billingAddressId && billingAddressId !== shippingAddressId || this.billingAddressSameAsShipping) {
                    this.billingAddressId = billingAddressId;
                }
                this.updateOrderSummary();
            }
        },
        // Shipping address was edited
        // Wipe the ID, and update the estimated shipping address' countryId, stateId and zipCode
        shippingAddress: {
            deep: true,
            handler() {
                this.shippingAddressId = null;
                const estimatedShippingAddress = {
                    countryId: this.shippingAddress.countryId,
                    stateId: this.shippingAddress.stateId,
                    zipCode: this.shippingAddress.zipCode
                };
                if (!isEqual(estimatedShippingAddress, this.estimatedShippingAddress)) {
                    this.estimatedShippingAddress = estimatedShippingAddress;
                }
            }
        },
        /**
         * Billing address was edited
         * Wipe the ID, and update the estimated billing address' countryId, stateId and zipCode
         */
        billingAddress: {
            deep: true,
            handler() {
                this.billingAddressId = null;
                const estimatedBillingAddress = {
                    countryId: this.billingAddress.countryId,
                    stateId: this.billingAddress.stateId,
                    zipCode: this.billingAddress.zipCode
                };
                if (!isEqual(estimatedBillingAddress, this.estimatedBillingAddress)) {
                    this.estimatedBillingAddress = estimatedBillingAddress;
                }
            }
        },
        /**
         * The user flicked the "billing address same as shipping" lightswitch
         * @param billingAddressSameAsShipping
         */
        billingAddressSameAsShipping(billingAddressSameAsShipping) {
            if (billingAddressSameAsShipping) {
                // Set the billing address in the cart to the same as the shipping address
                this.updateBackendCart({
                    billingAddressId: this.shippingAddressId,
                    billingAddressSameAsShipping: 1
                });
            } else if (this.billingAddressId) {
                this.updateBackendCart({
                    billingAddressId: this.billingAddressId
                });
            }
        },
        /**
         * Estimated shipping address changed
         * Update the backend cart
         */
        estimatedShippingAddress: {
            deep: true,
            handler() {
                this.updateBackendCart({
                    estimatedShippingAddress: this.estimatedShippingAddress,
                    estimatedBillingAddressSameAsShipping: this.billingAddressSameAsShipping ? 1 : 0
                })
            }
        },
        /**
         * Estimated billing address changed
         * Update the backend cart
         */
        estimatedBillingAddress: {
            deep: true,
            handler() {
                this.updateBackendCart({
                    estimatedBillingAddress: this.estimatedBillingAddress,
                    estimatedBillingAddressSameAsShipping: this.billingAddressSameAsShipping ? 1 : 0
                })
            }
        },
        /**
         * Shipping address' country ID changed
         * Wipe the state name and ID
         */
        shippingAddressCountryId() {
            this.shippingAddress = {
                ...this.shippingAddress,
                stateName: '',
                stateId: ''
            };
        },
        /**
         * Billing address' country ID changed
         * Wipe the state name and ID
         */
        billingAddressCountryId() {
            this.billingAddress = {
                ...this.billingAddress,
                stateName: '',
                stateId: ''
            };
        }
    },
    methods: {

        submitForm() {

            if (this.isSubmitting || this.hasSubmitted) {
                return;
            }

            let formIsValid = this.validateForm();

            // Extra validation - credit card
            if (!this.paymentMethodId && !this.hasEnteredCardDetails) {
                this.addError('paymentMethodId', t('Your card number is incomplete.'));
                formIsValid = false;
            } else {
                this.clearError('paymentMethodId');
            }

            // Eject if form contains errors
            if (!formIsValid) {
                this.errorMessage = VALIDATION_ERROR_ALERT;
                return;
            }

            this.isSubmitting = true;

            // Update the cart before submitting
            let cartData = {
                action: 'talormade/shop/checkout/update-cart',
                customerId: this.cart.customerId,
                number: this.cart.number,
                paymentSourceId: this.paymentSourceId,
                gatewayId: this.gatewayId,
                email: this.fields.email,
                acceptTerms: this.acceptTerms ? 1 : 0,
                registerNewUserOnOrderComplete: this.registerNewUserOnOrderComplete ? 1 : 0,
                // These custom fields are mostly relevant for guest orders
                fields: { ...this.fields }
            };

            // Add shipping address to the cart
            let cartAddressesData = {};
            if (this.shippingAddressId) {
                cartAddressesData.shippingAddressId = this.shippingAddressId;
            } else {
                cartAddressesData.shippingAddress = (({ useCustomerName, ...shippingAddress }) => ({
                    ...shippingAddress,
                    id: ''
                }))(this.shippingAddressComputed);
            }

            // Maybe add billing address to the cart
            if (!this.billingAddressSameAsShipping) {
                if (this.billingAddressId) {
                    cartAddressesData.billingAddressId = this.billingAddressId;
                } else {
                    cartAddressesData.billingAddress = (({ useCustomerName, ...billingAddress }) => ({
                        ...billingAddress,
                        id: ''
                    }))(this.billingAddressComputed);
                }
            } else {
                cartAddressesData.billingAddressId = this.shippingAddressId;
                cartAddressesData.billingAddressSameAsShipping = 1;
            }

            cartData = {
                ...cartData,
                ...cartAddressesData
            };

            getAxios()
                .post(null, cartData)
                .then(({ status, data }) => {
                    if (status !== 200 || !data.cart) {
                        throw new Error(data ? data.error : '');
                    }
                    const { errors, cart } = data;
                    this.updateCart(cart);
                    return errors || {};
                })
                .then(errors => {
                    // Maybe get payment method ID from Stripe
                    return new Promise((resolve, reject) => {
                        if (this.paymentMethodId) {
                            resolve(errors);
                            return;
                        }
                        createPaymentMethod(this.card, this.billingDetails)
                            .then(paymentMethodId => {
                                this.paymentMethodId = paymentMethodId;
                                resolve(errors);
                            })
                            .catch(error => {
                                resolve({
                                    ...errors,
                                    paymentMethodId: error.message || error
                                });
                            });
                    });
                })
                .then(errors => {
                    if (errors && Object.values(errors).length) {
                        this.errors = errors;
                        this.errorMessage = VALIDATION_ERROR_ALERT;
                        this.isSubmitting = false;
                    } else {
                        this.errors = null;
                        this.$refs.form.submit();
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.errorMessage = SERVER_ERROR_ALERT;
                    this.isSubmitting = false;
                });
        },

        updateBackendCart(data) {
            getAxios()
                .post(null, {
                    action: 'commerce/cart/update-cart',
                    ...data
                })
                .then(({ status, data }) => {
                    if (status !== 200 || !get(data, 'cart')) {
                        throw new Error();
                    }
                    Dispatch.emit(CART_UPDATED, { cart: data.cart });
                })
                .catch(error => {
                    console.error(error);
                });
        },

        updateCart(data) {
            // We don't want the *whole* cart, mind you
            const { number, email, billingAddressId, shippingAddressId, paymentSourceId, customerId, total } = data;
            this.cart = {
                number, email, billingAddressId, shippingAddressId, paymentSourceId, customerId, total
            };
        },

        /**
         * Fetch updated order summary HTML from the back end, and add it to this.$refs.summary
         */
        updateOrderSummary() {
            this.summaryIsReloading = true;
            getAxios()
                .post(null, { action: 'talormade/shop/checkout/get-order-summary-html' })
                .then(({ status, data }) => {
                    if (status !== 200 || !data.html) {
                        throw new Error();
                    }
                    $(this.$refs.summary).html(data.html);
                })
                .catch(error => {
                    console.error(error);
                })
                .then(() => {
                    this.summaryIsReloading = false;
                });
        },

        addError(key, error) {
            this.errors = set({
                ...(this.errors || {})
            }, key, error);
        },

        clearError(key) {
            if (!this.errors || !this.$_get(this.errors, key)) {
                return;
            }
            this.errors = omit(this.errors, key);
        },

        /**
         * Validate the form
         *
         * @returns {boolean}
         */
        validateForm() {
            let hasErrors = false;
            const inputs = this.$refs.form.querySelectorAll('input[name]:not([type="hidden"]),select[name]');
            inputs.forEach(input => {
                const error = validateInput(input);
                if (!error) {
                    this.clearError(input.name);
                    return;
                }
                this.addError(input.name, error);
                hasErrors = true;
            });
            return !hasErrors;
        },

        maybeCreateStripeElementsCard() {
            const cardNode = this.$refs.creditcard;
            if (!cardNode) {
                return;
            }
            initCardElement(cardNode)
                .then(card => {
                    this.card = card;
                    this.card.addEventListener('change', this.onCardChange);
                })
                .catch(error => {
                    this.addError('paymentMethodId', error.message || error);
                    console.error(error);
                });
        },

        /**
         * --------- Event listeners --------
         */
        onFormSubmit(e) {
            e.preventDefault();
            this.submitForm();
        },

        onCartUpdated(key, data) {
            const { cart } = data;
            this.updateCart(cart);
            const numLineItems = Object.values(cart.lineItems).length;
            if (!numLineItems) {
                window.location.href = '/shop';
            }
        },

        onCardChange(e) {
            // Clear out the payment method ID whenever the card input changes
            // This forces us to create and confirm a new PaymentIntent, if the user goes back and changes the card details after we already confirmed it
            this.hasEnteredCardDetails = !e.empty;
            this.paymentMethodId = null;
        },

        onAddressActionSelect(e) {
            const { triggerTarget: target } = e;
            // Figure out which address it belongs to
            const $addressCard = $(target).parent('[data-address]');
            const addressKey = $addressCard.data('address');
            switch (target.value) {
                case 'edit':
                    // Edit current address
                    let currentAddressId;
                    if (addressKey === 'shipping') {
                        currentAddressId = this.shippingAddressId;
                    } else if (addressKey === 'billing') {
                        currentAddressId = this.billingAddressId;
                    }
                    window.location.href = `/my-account/addresses/${currentAddressId}?returnUrl=${window.location.pathname.substr(1)}`;
                    break;
                case 'new':
                    // Add new address
                    window.location.href = `/my-account/addresses/new?returnUrl=${window.location.pathname.substr(1)}`;
                    break;
                default:
                    // Change selected address
                    const addressId = parseInt(target.value);
                    getAxios()
                        .post(null, {
                            action: 'talormade/addresses/default/get-address-card-html',
                            addressId,
                            customerId: this.cart.customerId,
                            showChangeActions: true
                        })
                        .then(({ status, data }) => {
                            if (status !== 200 || !data.html) {
                                throw new Error();
                            }
                            $addressCard.html(data.html);
                        })
                        .then(() => {
                            let data = {};
                            if (addressKey === 'shipping') {
                                data.shippingAddressId = addressId;
                                if (this.billingAddressSameAsShipping) {
                                    data.billingAddressId = this.shippingAddressId;
                                    data.billingAddressSameAsShipping = 1;
                                }
                            } else {
                                data.billingAddressId = addressId;
                            }
                            this.updateBackendCart(data);
                        })
                        .catch(error => {
                            console.error(error);
                            this.errorMessage = SERVER_ERROR_ALERT;
                        });
            }
        },

        onPaymentSourceActionSelect(e) {
            const { triggerTarget: target } = e;
            // Figure out which address it belongs to
            const $paymentSourceCard = $(target).parent('[data-paymentsource]');
            switch (target.value) {
                case 'new':
                    // Add new payment source
                    window.location.href = `/my-account/payment/new?returnUrl=${window.location.pathname.substr(1)}`;
                    break;
                default:
                    // Change selected address
                    const paymentSourceId = parseInt(target.value);
                    getAxios()
                        .post(null, {
                            action: 'talormade/payment/default/get-payment-source-card-html',
                            paymentSourceId,
                            customerId: this.cart.customerId,
                            showChangeActions: true
                        })
                        .then(({ status, data }) => {
                            const { html, paymentMethodId, paymentSourceId } = data;
                            if (status !== 200 || !html || !paymentSourceId || !paymentMethodId) {
                                throw new Error();
                            }
                            this.paymentSourceId = paymentSourceId;
                            this.paymentMethodId = paymentMethodId;
                            $paymentSourceCard.html(html);
                        })
                        .then(() => {
                            this.updateBackendCart({
                                paymentSourceId
                            });
                        })
                        .catch(error => {
                            console.error(error);
                            this.errorMessage = SERVER_ERROR_ALERT;
                        });
            }
        },

        /**
         * --------- Transitions --------
         */
        onBeforeFieldsetEnter(node) {
            gsap.set(node, { height: 0, opacity: 0 });
        },

        onFieldsetEnter(node, onComplete) {
            $('html').removeClass('no-outline').addClass('outline');
            const input = node.querySelector('input[type="text"],input[type="email"]');
            if (input) {
                input.focus();
            }
            gsap
                .timeline({ onComplete })
                .to(node, 0.5, { height: 'auto', ease: 'Cubic.easeOut' }, 0)
                .to(node, 0.5, { opacity: 1, ease: 'Cubic.easeIn' }, 0)
        },

        onFieldsetLeave(node, onComplete) {
            gsap
                .timeline({ onComplete })
                .to(node, 0.5, { height: 0, ease: 'Cubic.easeInOut' }, 0)
                .to(node, 0.35, { opacity: 0, ease: 'Cubic.easeIn' }, 0);
        }
    },
    mounted() {
        $(this.$el)
            .on('change', '[data-address] [data-actionselect]', this.onAddressActionSelect)
            .on('change', '[data-paymentsource] [data-actionselect]', this.onPaymentSourceActionSelect);
        Dispatch.on(CART_UPDATED, this.onCartUpdated);
        this.maybeCreateStripeElementsCard();
    },
    destroyed() {
        $(this.$el).off('change');
        Dispatch.off(CART_UPDATED, this.onCartUpdated);
        if (this.card) {
            this.card.removeEventListener('change', this.onCardChange);
        }
    }
});
