import $ from '@vaersaagod/tools/Dom';
import Dispatch from "@vaersaagod/tools/Dispatch";
import gsap from 'gsap';
import Vue from 'vue';
import { nl2br } from "../lib/helpers";
import { ADD_ERROR_ALERT, ADD_NOTICE_ALERT } from "../lib/events";

export default (el, props) => new Vue({
    el,
    delimiters: ['${', '}'],
    name: 'Alert',
    data() {
        return {
            key: props.key || 'error',
            message: props.message,
            focusOnClose: props.focusOnClose,
            duration: props.duration
        };
    },
    computed: {
        isClosed() {
            return !this.message;
        },
        htmlText() {
            if (!this.message) {
                return null;
            }
            return nl2br(this.message);
        }
    },
    methods: {
        stopTimeout() {
            if (!this.timeout) {
                return;
            }
            clearTimeout(this.timeout);
            delete this.timeout;
        },
        startTimeout() {
            this.stopTimeout();
            if (!this.duration || this.isClosed) {
                return;
            }
            this.timeout = setTimeout(this.close, this.duration * 1000);
        },
        close() {
            if (this.isClosed) {
                return;
            }
            this.stopTimeout();
            if (this.focusOnClose) {
                try { this.focusOnClose.focus() } catch (error) {};
            }
            this.message = null;
            this.focusOnClose = null;
            this.duration = null;
        },
        // Event handlers
        onAlert(key, { message = null, duration = null, focusOnClose }) {
            this.message = message;
            this.duration = duration;
            if (!focusOnClose) {
                this.focusOnClose = false;
            } else if (focusOnClose === true) {
                this.focusOnClose = document.activeElement || null;
            } else {
                this.focusOnClose = $(focusOnClose).get(0);
            }
            this.stopTimeout();
            Vue.nextTick(this.startTimeout);
        },
        onMouseEnter() {
            this.stopTimeout();
        },
        onMouseLeave() {
            this.startTimeout();
        },
        // Transitions
        onEnter(node, onComplete) {
            gsap.timeline({
                onComplete
            })
                .fromTo(node, 0.75, { yPercent: -100 }, { yPercent: 0 , ease: 'Quint.easeOut' }, 0)
                .fromTo(node, 0.25, { opacity: 0 }, { opacity: 1, ease: 'Cubic.easeIn' }, 0);
        },
        onLeave(node, onComplete) {
            gsap.timeline({
                onComplete
            })
                .to(node, 0.35, { yPercent: -100, ease: 'Cubic.easeIn' }, 0);
        }
    },
    mounted() {
        if (this.key === 'error') {
            Dispatch.on(ADD_ERROR_ALERT, this.onAlert, true);
        } else if (this.key === 'notice') {
            Dispatch.on(ADD_NOTICE_ALERT, this.onAlert, true);
        }
        this.startTimeout();
    },
    destroyed() {
        if (this.key === 'error') {
            Dispatch.off(ADD_ERROR_ALERT, this.onAlert, true);
        } else if (this.key === 'notice') {
            Dispatch.off(ADD_NOTICE_ALERT, this.onAlert, true);
        }
        this.stopTimeout();
    }
});
