<script>
    // Tiny renderless component basically adding or removing the class "is-empty", to toggle the mock placeholder span's visibility
    export default {
        name: "DropdownInput",
        render() {
            return this.$slots.default;
        },
        methods: {
            onChange(e) {
                const select = e ? e.target : this.$el.querySelector('select');
                if (select.value) {
                    select.classList.remove('is-empty');
                } else {
                    select.classList.add('is-empty');
                }
            }
        },
        mounted() {
            this.$el.querySelector('select').addEventListener('change', this.onChange);
            this.onChange();
        },
        destroyed() {
            this.$el.querySelector('select').removeEventListener('change', this.onChange);
        }
    }
</script>
