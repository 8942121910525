import $ from '@vaersaagod/tools/Dom';

import Jelly from '../lib/jelly.js';
import ResizeObserver from 'resize-observer-polyfill';

import gsap from 'gsap';

const CANVAS_PADDING = 80;

export default (el, props) => {

    const $btn = $(el);
    const color = $btn.css('backgroundColor');
    const borderColor = $btn.css('color');
    const hasBorder = !!parseInt($btn.css('borderLeftWidth').replace('px', ''), 10);

    const { pointsNumber, intensity, maxDistance, mouseIncidence, maxIncidence, fastness, debug } = props || {};

    const hiDpi = (window.devicePixelRatio || 1) > 1;

    let resizeObserver;
    let jelly;

    // Make sure that the button has a non-static position
    if ($btn.css('position') === 'static') {
        $btn.addClass('pos-r');
    }

    const $btnContent = $(el.firstElementChild);
    if ($btnContent.css('position') === 'static') {
        $btnContent.addClass('pos-r z-1');
    }

    // Create a canvas element
    const $canvas = $('<canvas class="pos-a pe-n u-gpu" />');
    $btn.append($canvas);

    const getPathD = (x, y, w, h, r) => {
        const p = (x, y) => x + " " + y + " ";
        let strPath = "M" + p(x + r, y); //A
        strPath += "L" + p(x + w - r, y) + "Q" + p(x + w, y) + p(x + w, y + r); //B
        strPath += "L" + p(x + w, y + h - r) + "Q" + p(x + w, y + h) + p(x + w - r, y + h); //C
        strPath += "L" + p(x + r, y + h) + "Q" + p(x, y + h) + p(x, y + h - r); //D
        strPath += "L" + p(x, y + r) + "Q" + p(x, y) + p(x + r, y); //A
        strPath += "Z";
        return strPath;
    };

    /**
     * Return SVG <path> element with the button shape
     * @param width
     * @param height
     * @param rx
     * @param ry
     * @param x
     * @param y
     * @returns {unknown[]}
     */
    const getPath = ({ x, y, width, height, radius }) => {
        const $svg = $(`
            <svg xmlns="http://www.w3.org/2000/svg">
                <path></path>
            </svg>
        `);
        const $path = $svg.find('path');
        $path.attr('d', getPathD(x, y, width, height, radius));
        return $path.get(0);
    };

    /**
     * Create or update the jelly effect
     */
    const createJelly = () => {

        // Get the current button dimensions and border-radius, and update the SVG path
        const width = $btn.width();
        const height = $btn.height();
        const canvasWidth = width + CANVAS_PADDING;
        const canvasHeight = height + CANVAS_PADDING;
        const borderRadius = parseInt($btn.css('borderBottomLeftRadius').replace('px', ''), 0);

        // Update the canvas size and position
        const canvas = $canvas.get(0);
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        $canvas
            .css({
                width: canvasWidth,
                height: canvasHeight,
                left: -(CANVAS_PADDING / 2),
                top: -(CANVAS_PADDING / 2)
            });

        // Get the SVG <path>
        const path = getPath({
            x: Math.round((canvasWidth - width) / 2),
            y: Math.round((canvasHeight - height) / 2),
            width,
            height,
            radius: borderRadius
        });

        // Create the jelly
        let opts = {
            paths: [path],
            color,
            pointsNumber: parseInt(pointsNumber, 10) || 40,
            maxDistance: parseFloat(maxDistance) || 70,
            mouseIncidence: parseFloat(mouseIncidence) || 40,
            maxIncidence: parseFloat(maxIncidence) || 40,
            intensity: parseFloat(intensity) || 0.35,
            fastness: parseFloat(fastness) || 0.025
        };

        if (hasBorder && borderColor) {
            opts = {
                ...opts,
                border: borderColor,
                borderWidth: 2
            };
        }

        // Transform the button contents to match the "centroid" transform made by jelly.js
        $btnContent.css({
            transform: `translate(-${Math.round(canvasWidth / 2)}px, -${Math.round(canvasHeight / 2)}px)`
        });

        if (!jelly) {
            jelly = new Jelly($canvas.get(0), {
                ...opts,
                centroid: $btnContent.get(0).firstElementChild,
                hiDpi: hiDpi,
                debug: !!debug
            });
            // Remove the background from the button
            $btn.css({ backgroundColor: 'transparent', borderColor: 'transparent' });
        } else {
            jelly.setWidth(canvasWidth);
            jelly.setHeight(canvasHeight);
            jelly.morph({
                ...opts,
                animate: false
            });
        }

    };

    const init = () => {
        resizeObserver = new ResizeObserver(createJelly);
        resizeObserver.observe(el);

        requestAnimationFrame(() => {
            $btn.addClass('js-jello-inited');
            gsap.fromTo($btn.get(0), { opacity: 0 }, { opacity: 1, duration: 0.3 });
        });
    };

    const destroy = () => {
        resizeObserver.disconnect();
        if (jelly) {
            jelly.destroy();
        }
    };

    return {
        init,
        destroy
    };

};
