import $ from '@vaersaagod/tools/Dom';
import Dispatch from "@vaersaagod/tools/Dispatch";
import Vue from 'vue';

import gsap from 'gsap';
import serialize from 'form-serialize';
import set from 'lodash/set';
import omit from 'lodash/omit';
import find from 'lodash/find';
import StateInput from "../../vue/StateInput";

import { getAxios, t } from "../../lib/helpers";
import { initCardElement, confirmCardSetup } from '../../lib/stripeCardElementFacade';
import { validateInput } from "../../lib/validate";

import { ADD_ERROR_ALERT, NEW_SUBSCRIPTION_UPDATED } from "../../lib/events";

const VALIDATION_ERROR_ALERT = t('ERROR_MESSAGE_VALIDATION');
const SERVER_ERROR_ALERT = t('ERROR_MESSAGE_SERVER');

const ERROR_ALERT_DURATION = 5;

export default (el, props) => new Vue({
    el,
    delimiters: ['${', '}'], // The only reason to use custom delimiters, is if you want to mix Vue and Twig templates,
    name: 'Checkout',
    components: {
        StateInput
    },
    data() {
        return {
            user: props.user,
            customerId: props.customerId,
            shippingAddressId: props.shippingAddressId,
            shippingAddress: props.shippingAddress,
            billingAddressId: props.billingAddressId,
            billingAddress: props.billingAddress,
            billingAddressSameAsShipping: props.billingAddressSameAsShipping,
            acceptTerms: props.acceptTerms,
            paymentMethodId: props.paymentMethodId,
            paymentSourceId: props.paymentSourceId,
            nameOnCard: props.nameOnCard,
            hasEnteredCardDetails: false,
            errors: null,
            errorMessage: null,
            formEnabled: !!props.paymentMethodId,
            summaryIsReloading: false,
            isSubmitting: false,
            hasSubmitted: false
        };
    },
    computed: {
        /**
         *
         * @returns {boolean}
         */
        hasErrors() {
            return this.errors && Object.keys(this.errors).length;
        },
        /**
         * Get billing details for use with Stripe SetupIntents
         * @returns {{address: {country: (*|null), city: (null|string), state: null, postal_code: (null|shippingAddress.shippingAddress.zipCode|billingAddress.billingAddress.zipCode), line2: null, line1: null}, name: string, email: Document.email}}
         */
        billingDetails() {
            let name;
            const addressToUse = this.billingAddressSameAsShipping ? this.shippingAddress : this.billingAddress;
            if (this.nameOnCard) {
                // Use name on card field
                name = this.nameOnCard;
            } else if (addressToUse.useCustomerName) {
                // Use the customer name
                name = [this.user.firstName, this.user.lastName];
            } else {
                // Use the address' name
                name = [addressToUse.firstName, addressToUse.lastName];
            }
            const { stateId, stateName } = addressToUse;
            let state = null;
            if (stateId) {
                state = find(props.states, { id: stateId.toString() }, null);
                state = state && state.name ? state.name : null;
            } else if (stateName) {
                state = stateName;
            }
            return {
                name: [].concat(name).filter(i => !!i).join(' '),
                email: this.user.email,
                address: {
                    city: addressToUse.city,
                    country: (find(props.countryIsos, { id: addressToUse.countryId }) || {}).iso || null,
                    line1: addressToUse.address1,
                    line2: addressToUse.address2,
                    postal_code: addressToUse.zipCode,
                    state
                }
            };
        },
        shippingCountryId() {
            return this.shippingAddress.countryId;
        },
        billingCountryId() {
            return this.billingAddress.countryId;
        }
    },
    watch: {
        errors() {
            if (this.hasErrors) {
                // Focus to first input with an error
                Vue.nextTick(() => {
                    $('html').addClass('outline').removeClass('no-outline');
                    const firstErrorInput = this.$refs.form.querySelector('[data-haserror]');
                    if (!firstErrorInput && this.card && !!this.errors.paymentMethodId) {
                        this.card.focus();
                    } else if (firstErrorInput) {
                        try {
                            firstErrorInput.focus();
                        } catch (error) {
                            // Don't care.
                        }
                    }
                });
            }
        },
        errorMessage(message) {
            if (!message) {
                return null;
            }
            Dispatch.emit(ADD_ERROR_ALERT, {
                message,
                duration: ERROR_ALERT_DURATION
            });
            this.errorMessage = null;
        },
        shippingCountryId() {
            this.shippingAddress = {
                ...this.shippingAddress,
                stateName: '',
                stateId: ''
            };
            this.updateSession();
        },
        billingCountryId() {
            this.billingAddress = {
                ...this.billingAddress,
                stateName: '',
                stateId: ''
            };
            this.updateSession();
        },
        shippingAddressId() {
            this.updateSession();
        },
        billingAddressId() {
            this.updateSession();
        }
    },
    methods: {

        addError(key, error) {
            this.errors = set({
                ...(this.errors || {})
            }, key, error);
        },

        clearError(key) {
            if (!this.errors || !this.$_get(this.errors, key)) {
                return;
            }
            this.errors = omit(this.errors, key);
        },

        /**
         * Validate the form
         *
         * @returns {boolean}
         */
        validateForm() {
            let hasErrors = false;
            const inputs = this.$refs.form.querySelectorAll('input[name]:not([type="hidden"]),select[name]');
            inputs.forEach(input => {
                const error = validateInput(input);
                if (!error) {
                    this.clearError(input.name);
                    return;
                }
                this.addError(input.name, error);
                hasErrors = true;
            });
            return !hasErrors;
        },

        updateSession() {

            const data = {
                shippingAddressId: this.shippingAddressId,
                shippingCountryId: this.shippingCountryId,
                billingAddressId: this.billingAddressId,
                billingCountryId: this.billingCountryId
            };

            getAxios()
                .post(null, {
                    action: 'talormade/memberships/subscribe/update-session',
                    ...data
                })
                .then(({ status, data }) => {
                    if (status !== 200) {
                        throw new Error();
                    }
                    Dispatch.emit(NEW_SUBSCRIPTION_UPDATED, data);
                    this.updateSummary();
                })
                .catch(error => {
                    console.error(error);
                });

        },

        /**
         *
         */
        submitForm() {

            if (!this.formEnabled || this.isSubmitting || this.hasSubmitted) {
                return;
            }

            // Validate required fields client side
            let formIsValid = this.validateForm();

            // Extra validation: Check that the passwords match, if passwords have been added
            const { newPassword, repeatPassword } = this.user;
            if (newPassword && repeatPassword && newPassword !== repeatPassword) {
                this.addError('user.repeatPassword', t('Passwords do not match.'));
                formIsValid = false;
                return;
            }

            // Extra validation - credit card
            if (!this.paymentMethodId && !this.hasEnteredCardDetails) {
                this.addError('paymentMethodId', t('Your card number is incomplete.'));
                formIsValid = false;
            } else {
                this.clearError('paymentMethodId');
            }

            // Eject if form contains errors
            if (!formIsValid) {
                this.errorMessage = VALIDATION_ERROR_ALERT;
                return;
            }

            this.isSubmitting = true;
            this.errorMessage = null;

            if (!this.paymentMethodId) {
                // No payment method ID found – confirm card setup w/ Stripe
                confirmCardSetup(this.card, this.billingDetails)
                    .then(paymentMethodId => {
                        this.clearError('paymentMethodId');
                        this.paymentMethodId = paymentMethodId;
                    })
                    .catch(error => {
                        this.addError('paymentMethodId', error.message || error);
                        this.errorMessage = VALIDATION_ERROR_ALERT;
                        this.card.focus();
                    })
                    .then(() => {
                        this.isSubmitting = false;
                        Vue.nextTick(() => {
                            if (!this.paymentMethodId) {
                                return;
                            }
                            this.submitForm();
                        });
                    });
                return;
            }

            // Finally, actually submit the form.
            const client = getAxios();
            const data = serialize(this.$refs.form, { hash: true });

            client
                .post(null, data)
                .then(({ status, data }) => {
                    if (status !== 200) {
                        throw new Error();
                    }
                    const { errors = null, errorMessage = null, redirect } = data;
                    if (errors) {
                        this.errors = errors;
                    }
                    if (errorMessage) {
                        this.errorMessage = errorMessage;
                    }
                    if (!errors && !errorMessage) {
                        this.hasSubmitted = true;
                        window.location.href = `/${redirect}`;
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.errorMessage = SERVER_ERROR_ALERT;
                })
                .then(() => {
                    this.isSubmitting = false;
                });

        },

        /**
         * Fetch updated order summary HTML from the back end, and add it to this.$refs.summary
         */
        updateSummary() {
            let data = {};
            if (this.shippingAddressId) {
                data.shippingAddressId = this.shippingAddressId;
            } else if (this.shippingCountryId) {
                data.countryId = this.shippingCountryId;
            }
            getAxios()
                .post(null, {
                    action: 'talormade/memberships/subscribe/get-summary-html',
                    ...data
                })
                .then(({ status, data }) => {
                    if (status !== 200 || !data.html) {
                        throw new Error();
                    }
                    $(this.$refs.summary).html(data.html);
                })
                .catch(error => {
                    console.error(error);
                });
        },

        /**
         * --------- Event handlers --------
         */

        onFormSubmit(e) {
            e.preventDefault();
            this.submitForm();
        },

        onCardChange(e) {
            // Clear out the payment method ID whenever the card input changes
            // This forces us to create and confirm a new SetupIntent if the user goes back and changes the card details after we already confirmed it
            this.hasEnteredCardDetails = !e.empty;
            this.paymentMethodId = null;
        },

        onAddressActionSelect(e) {
            const { triggerTarget: target } = e;
            // Figure out which address it belongs to
            const $addressCard = $(target).parent('[data-address]');
            const addressKey = $addressCard.data('address');
            switch (target.value) {
                case 'edit':
                    // Edit current address
                    let currentAddressId;
                    if (addressKey === 'shipping') {
                        currentAddressId = this.shippingAddressId;
                    } else if (addressKey === 'billing') {
                        currentAddressId = this.billingAddressId;
                    }
                    window.location.href = `/my-account/addresses/${currentAddressId}?returnUrl=${window.location.pathname.substr(1)}`;
                    break;
                case 'new':
                    // Add new address
                    window.location.href = `/my-account/addresses/new?returnUrl=${window.location.pathname.substr(1)}`;
                    break;
                default:
                    // Change selected address
                    const addressId = parseInt(target.value);
                    getAxios()
                        .post(null, {
                            action: 'talormade/addresses/default/get-address-card-html',
                            addressId,
                            customerId: this.customerId,
                            showChangeActions: true
                        })
                        .then(({ status, data }) => {
                            if (status !== 200 || !data.html) {
                                throw new Error();
                            }
                            $addressCard.html(data.html);
                        })
                        .then(() => {
                            if (addressKey === 'shipping') {
                                this.shippingAddressId = addressId;
                            } else if (addressKey === 'billing') {
                                this.billingAddressId = addressId;
                            }

                        })
                        .catch(error => {
                            console.error(error);
                            this.errorMessage = SERVER_ERROR_ALERT;
                        });
            }
        },

        onPaymentSourceActionSelect(e) {
            const { triggerTarget: target } = e;
            // Figure out which address it belongs to
            const $paymentSourceCard = $(target).parent('[data-paymentsource]');
            switch (target.value) {
                case 'new':
                    // Add new payment source
                    window.location.href = `/my-account/payment/new?returnUrl=${window.location.pathname.substr(1)}`;
                    break;
                default:
                    // Change selected address
                    const paymentSourceId = parseInt(target.value);
                    getAxios()
                        .post(null, {
                            action: 'talormade/payment/default/get-payment-source-card-html',
                            paymentSourceId,
                            customerId: this.customerId,
                            showChangeActions: true
                        })
                        .then(({ status, data }) => {
                            const { html, paymentMethodId, paymentSourceId } = data;
                            if (status !== 200 || !html || !paymentSourceId || !paymentMethodId) {
                                throw new Error();
                            }
                            this.paymentSourceId = paymentSourceId;
                            this.paymentMethodId = paymentMethodId;
                            $paymentSourceCard.html(html);
                        })
                        .catch(error => {
                            console.error(error);
                            this.errorMessage = SERVER_ERROR_ALERT;
                        });
            }
        },

        /**
         * --------- Transitions --------
         */

        onBeforeFieldsetEnter(node) {
            gsap.set(node, { height: 0, opacity: 0 });
        },

        onFieldsetEnter(node, onComplete) {
            $('html').removeClass('no-outline').addClass('outline');
            const input = node.querySelector('input[type="text"],input[type="email"]');
            if (input) {
                input.focus();
            }
            gsap
                .timeline({ onComplete })
                .to(node, 0.5, { height: 'auto', ease: 'Cubic.easeOut' }, 0)
                .to(node, 0.5, { opacity: 1, ease: 'Cubic.easeIn' }, 0)
        },

        onFieldsetLeave(node, onComplete) {
            gsap
                .timeline({ onComplete })
                .to(node, 0.5, { height: 0, ease: 'Cubic.easeInOut' }, 0)
                .to(node, 0.35, { opacity: 0, ease: 'Cubic.easeIn' }, 0);
        }
    },
    mounted() {
        // Initialize address and payment source action selects
        $(this.$el)
            .on('change', '[data-address] [data-actionselect]', this.onAddressActionSelect)
            .on('change', '[data-paymentsource] [data-actionselect]', this.onPaymentSourceActionSelect);
        // Initialize the Stripe Elements card
        const cardNode = this.$refs.creditcard;
        if (cardNode) {
            initCardElement(cardNode)
                .then(card => {
                    this.card = card;
                    this.card.addEventListener('change', this.onCardChange);
                    this.formEnabled = true;
                })
                .catch(error => {
                    console.error(error);
                    this.addError('paymentMethodId', error.message || error);
                });
        }
    },
    destroyed() {
        $(this.$el).off('change');
        if (this.card) {
            this.card.removeEventListener('change', this.onCardChange);
        }
    }
});
